import { resourcePath } from "../paths.js";
import { captureException } from "@sentry/react";

// Mapping names in the potree files to shader names.
export const attributeLocations = {
  position: { name: "position", location: 0 },
  color: { name: "color", location: 1 },
  rgba: { name: "color", location: 1 },
  intensity: { name: "intensity", location: 2 },
  classification: { name: "classification", location: 3 },
  returnNumber: { name: "returnNumber", location: 4 },
  "return number": { name: "returnNumber", location: 4 },
  returns: { name: "returnNumber", location: 4 },
  numberOfReturns: { name: "numberOfReturns", location: 5 },
  "number of returns": { name: "numberOfReturns", location: 5 },
  pointSourceID: { name: "pointSourceID", location: 6 },
  "source id": { name: "pointSourceID", location: 6 },
  "point source id": { name: "pointSourceID", location: 6 },
  indices: { name: "indices", location: 7 },
  normal: { name: "normal", location: 8 },
  spacing: { name: "spacing", location: 9 },
  "gps-time": { name: "gpsTime", location: 10 },
  aExtra: { name: "aExtra", location: 11 },
  userData: { name: "userData", location: 12 },
};

let Shaders = {};

export async function load() {
  const requiredShaders = [
    // Point cloud.
    "pointcloud.vert",
    "pointcloud.frag",
    "edl.vert",
    "edl.frag",
    // Images.
    "flat_mesh.vert",
    "flat_mesh.frag",
    // Beams.
    "new_line.vert",
    "new_line.frag",
    // Mesh rendering
    "mesh/basic.vert",
    "mesh/basic.frag",
    "mesh/basic_textured.frag",
    "mesh/normal.frag",
    "mesh/line.vert",
    "mesh/basic_billboard.vert",
    // Skybox
    "mesh/skybox.vert",
    "mesh/skybox.frag",
  ];

  console.info(`Loading ${requiredShaders.length} shaders...`);
  await Promise.all(
    requiredShaders.map(async (fileName) => {
      const path = `${resourcePath}/shaders/${fileName}`;
      try {
        const response = await fetch(path);
        if (!response.ok) throw new Error(`Failed to load shader: ${fileName}`);
        Shaders[fileName] = await response.text();
        console.log(`Loaded ${fileName}!`);
      } catch (error) {
        const exceptionHint = {
          event_id: "shaders.load",
          originalException: error,
        };

        captureException(error, exceptionHint);
        console.error(error);
      }
    })
  );
  console.info("Loaded shaders!");
}

export function get(shader) {
  return Shaders[shader];
}

export class Shader {
  gl: WebGL2RenderingContext;
  name: string;

  vsSource: string = "";
  vertexShader: WebGLShader = null;

  fsSource: string = "";
  fragmentShader: WebGLShader = null;

  program: WebGLProgram = null;

  cache = new Map();

  uniformLocations = {};
  attributeLocations = {};
  uniforms = {};

  constructor(
    gl: WebGL2RenderingContext,
    name: string,
    vsSource: string,
    fsSource: string
  ) {
    this.gl = gl;
    this.name = name;
    this.vsSource = vsSource;
    this.fsSource = fsSource;

    this.update(vsSource, fsSource);
  }

  update(vsSource: string, fsSource: string) {
    this.vsSource = vsSource;
    this.fsSource = fsSource;

    this.linkProgram();
  }

  compileShader(shader, source) {
    let gl = this.gl;

    gl.shaderSource(shader, source);

    gl.compileShader(shader);

    const success = gl.getShaderParameter(shader, gl.COMPILE_STATUS);
    if (!success) {
      const info = gl.getShaderInfoLog(shader);
      const numberedSource = source
        .split("\n")
        .map((a, i) => `${i + 1}`.padEnd(5) + a)
        .join("\n");

      throw new Error(
        `Could not compile shader ${this.name}: ${info}, \n${numberedSource}`
      );
    }
  }

  linkProgram() {
    const tStart = performance.now();

    let gl = this.gl;

    this.uniformLocations = {};
    this.attributeLocations = {};
    this.uniforms = {};

    gl.useProgram(null);

    {
      const cached = this.cache.get(`${this.vsSource}, ${this.fsSource}`);
      if (cached) {
        this.program = cached.program;
        this.vertexShader = cached.vs;
        this.fragmentShader = cached.fs;
        this.attributeLocations = cached.attributeLocations;
        this.uniformLocations = cached.uniformLocations;
        this.uniforms = cached.uniforms;

        return;
      }
    }

    this.vertexShader = gl.createShader(gl.VERTEX_SHADER);
    this.fragmentShader = gl.createShader(gl.FRAGMENT_SHADER);
    this.program = gl.createProgram();

    for (const attribute of Object.values(attributeLocations)) {
      let location = attribute.location;
      let glslName = attribute.name;
      gl.bindAttribLocation(this.program, location, glslName);
    }

    this.compileShader(this.vertexShader, this.vsSource);
    this.compileShader(this.fragmentShader, this.fsSource);

    let program = this.program;

    gl.attachShader(program, this.vertexShader);
    gl.attachShader(program, this.fragmentShader);

    gl.linkProgram(program);

    gl.detachShader(program, this.vertexShader);
    gl.detachShader(program, this.fragmentShader);

    let success = gl.getProgramParameter(program, gl.LINK_STATUS);
    if (!success) {
      let info = gl.getProgramInfoLog(program);
      throw new Error(`Couldn't link program ${this.name}: ${info}`);
    }

    {
      // attribute locations
      const numAttributes = gl.getProgramParameter(
        program,
        gl.ACTIVE_ATTRIBUTES
      );

      for (let i = 0; i < numAttributes; i++) {
        let attribute = gl.getActiveAttrib(program, i);

        let location = gl.getAttribLocation(program, attribute.name);

        this.attributeLocations[attribute.name] = location;
      }
    }

    {
      // uniform locations
      const numUniforms = gl.getProgramParameter(program, gl.ACTIVE_UNIFORMS);

      for (let i = 0; i < numUniforms; i++) {
        const uniform = gl.getActiveUniform(program, i);
        const location = gl.getUniformLocation(program, uniform.name);

        this.uniformLocations[uniform.name] = location;
        this.uniforms[uniform.name] = {
          location: location,
          value: null,
        };
      }
    }

    const cached = {
      program: this.program,
      vs: this.vertexShader,
      fs: this.fragmentShader,
      attributeLocations: this.attributeLocations,
      uniformLocations: this.uniformLocations,
      uniforms: this.uniforms,
    };

    this.cache.set(`${this.vsSource}, ${this.fsSource}`, cached);

    const tEnd = performance.now();
    const duration = tEnd - tStart;

    console.log(`Shader compilation time: ${duration.toFixed(3)}`);
  }

  setUniformMatrix4(name, value) {
    const gl = this.gl;
    const location = this.uniformLocations[name];

    if (location == null) {
      return;
    }

    let tmp = new Float32Array(value.elements);
    gl.uniformMatrix4fv(location, false, tmp);
  }

  setUniform1f(name, value) {
    const gl = this.gl;
    const uniform = this.uniforms[name];

    if (uniform === undefined) {
      return;
    }

    uniform.value = value;

    gl.uniform1f(uniform.location, value);
  }

  setUniformBoolean(name, value) {
    const gl = this.gl;
    const uniform = this.uniforms[name];

    if (uniform === undefined) {
      return;
    }

    uniform.value = value;

    gl.uniform1i(uniform.location, value);
  }

  setUniformTexture(name, value) {
    const gl = this.gl;
    const location = this.uniformLocations[name];

    if (location == null) {
      return;
    }

    gl.uniform1i(location, value);
  }

  setUniform2f(name, value) {
    const gl = this.gl;
    const location = this.uniformLocations[name];

    if (location == null) {
      return;
    }

    gl.uniform2f(location, value[0], value[1]);
  }

  setUniform3f(name, value) {
    const gl = this.gl;
    const location = this.uniformLocations[name];

    if (location == null) {
      return;
    }

    gl.uniform3f(location, value[0], value[1], value[2]);
  }

  setUniform1i(name, value) {
    let gl = this.gl;
    let location = this.uniformLocations[name];

    if (location == null) {
      return;
    }

    gl.uniform1i(location, value);
  }
}
