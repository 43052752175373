import { CanvasTexture, Texture } from "../potree/rendering/types";
import { Vector4 } from "potree/mathtypes";
import { DataTexture } from "../potree/rendering/datatexture";
import {
  ClampToEdgeWrapping,
  RGBFormat,
  RGBAFormat,
  DepthFormat,
  DepthStencilFormat,
  LinearMipMapLinearFilter,
  RepeatWrapping,
  MirroredRepeatWrapping,
  NormalBlending,
  NoBlending,
  CustomBlending,
  AdditiveBlending,
  SubtractiveBlending,
  MultiplyBlending,
  SrcAlphaFactor,
  ZeroFactor,
  OneFactor,
  SrcColorFactor,
  SrcAlphaSaturateFactor,
  OneMinusSrcAlphaFactor,
  OneMinusSrcColorFactor,
  DstAlphaFactor,
  DstColorFactor,
  OneMinusDstAlphaFactor,
  OneMinusDstColorFactor,
  AddEquation,
  LessEqualDepth,
  NeverDepth,
  GreaterDepth,
  GreaterEqualDepth,
  LessDepth,
  AlwaysDepth,
  EqualDepth,
  NotEqualDepth,
  SubtractEquation,
  ReverseSubtractEquation,
  BackSide,
  DoubleSide,
  CullFaceBack,
  CullFaceFront,
  CullFaceNone,
  NearestFilter,
  LinearFilter,
  NearestMipMapLinearFilter,
  NearestMipMapNearestFilter,
  LinearMipMapNearestFilter,
  MinEquation,
  MaxEquation,
  FloatType,
  UnsignedInt248Type,
  UnsignedIntType,
  UnsignedByteType,
  UnsignedShortType,
  ByteType,
  ShortType,
  IntType,
  HalfFloatType,
  UnsignedShort4444Type,
  UnsignedShort5551Type,
  UnsignedShort565Type,
  RGBA_ASTC_10x10_Format,
  RGBA_ASTC_10x5_Format,
  RGBA_ASTC_10x6_Format,
  RGBA_ASTC_10x8_Format,
  RGBA_ASTC_12x10_Format,
  RGBA_ASTC_12x12_Format,
  RGBA_ASTC_4x4_Format,
  RGBA_ASTC_5x4_Format,
  RGBA_ASTC_5x5_Format,
  RGBA_ASTC_6x5_Format,
  RGBA_ASTC_6x6_Format,
  RGBA_ASTC_8x5_Format,
  RGBA_ASTC_8x6_Format,
  RGBA_ASTC_8x8_Format,
  RGBA_BPTC_Format,
  RGBA_ETC2_EAC_Format,
  RGB_S3TC_DXT1_Format,
  RGBA_S3TC_DXT1_Format,
  RGBA_S3TC_DXT3_Format,
  RGBA_S3TC_DXT5_Format,
  RGB_ETC1_Format,
  RGB_ETC2_Format,
  RGB_PVRTC_2BPPV1_Format,
  RGBA_PVRTC_2BPPV1_Format,
  RGB_PVRTC_4BPPV1_Format,
  RGBA_PVRTC_4BPPV1_Format,
  SRGB8_ALPHA8_ASTC_10x10_Format,
  SRGB8_ALPHA8_ASTC_10x5_Format,
  SRGB8_ALPHA8_ASTC_10x6_Format,
  SRGB8_ALPHA8_ASTC_10x8_Format,
  SRGB8_ALPHA8_ASTC_12x10_Format,
  SRGB8_ALPHA8_ASTC_12x12_Format,
  SRGB8_ALPHA8_ASTC_4x4_Format,
  SRGB8_ALPHA8_ASTC_5x4_Format,
  SRGB8_ALPHA8_ASTC_5x5_Format,
  SRGB8_ALPHA8_ASTC_6x5_Format,
  SRGB8_ALPHA8_ASTC_6x6_Format,
  SRGB8_ALPHA8_ASTC_8x5_Format,
  SRGB8_ALPHA8_ASTC_8x6_Format,
  SRGB8_ALPHA8_ASTC_8x8_Format,
  RedFormat,
  RedIntegerFormat,
  RGIntegerFormat,
  RGBIntegerFormat,
  RGBAIntegerFormat,
  AlphaFormat,
  LuminanceAlphaFormat,
  LuminanceFormat,
  RGFormat,
} from "../potree/rendering/constants.js";

function WebGLAnimation() {
  let context = null;
  let isAnimating = false;
  let animationLoop = null;
  let requestId = null;

  function onAnimationFrame(time, frame) {
    animationLoop(time, frame);

    requestId = context.requestAnimationFrame(onAnimationFrame);
  }

  return {
    start: function () {
      if (isAnimating === true) return;
      if (animationLoop === null) return;

      requestId = context.requestAnimationFrame(onAnimationFrame);

      isAnimating = true;
    },

    stop: function () {
      context.cancelAnimationFrame(requestId);

      isAnimating = false;
    },

    setAnimationLoop: function (callback) {
      animationLoop = callback;
    },

    setContext: function (value) {
      context = value;
    },
  };
}

function WebGLAttributes(gl) {
  const buffers = new WeakMap();

  function createBuffer(attribute, bufferType) {
    const array = attribute.array;
    const usage = attribute.usage;

    const buffer = gl.createBuffer();

    gl.bindBuffer(bufferType, buffer);
    gl.bufferData(bufferType, array, usage);

    attribute.onUploadCallback();

    let type = 5126;

    if (array instanceof Float32Array) {
      type = 5126;
    } else if (array instanceof Float64Array) {
      console.warn(
        "THREE.WebGLAttributes: Unsupported data buffer format: Float64Array."
      );
    } else if (array instanceof Uint16Array) {
      if (attribute.isFloat16BufferAttribute) {
        type = 5131;
      } else {
        type = 5123;
      }
    } else if (array instanceof Int16Array) {
      type = 5122;
    } else if (array instanceof Uint32Array) {
      type = 5125;
    } else if (array instanceof Int32Array) {
      type = 5124;
    } else if (array instanceof Int8Array) {
      type = 5120;
    } else if (array instanceof Uint8Array) {
      type = 5121;
    }

    return {
      buffer: buffer,
      type: type,
      bytesPerElement: array.BYTES_PER_ELEMENT,
      version: attribute.version,
    };
  }

  function updateBuffer(buffer, attribute, bufferType) {
    const array = attribute.array;
    const updateRange = attribute.updateRange;

    gl.bindBuffer(bufferType, buffer);

    if (updateRange.count === -1) {
      // Not using update ranges

      gl.bufferSubData(bufferType, 0, array);
    } else {
      gl.bufferSubData(
        bufferType,
        updateRange.offset * array.BYTES_PER_ELEMENT,
        array,
        updateRange.offset,
        updateRange.count
      );

      updateRange.count = -1; // reset range
    }
  }

  //

  function get(attribute) {
    if (attribute.isInterleavedBufferAttribute) attribute = attribute.data;

    return buffers.get(attribute);
  }

  function remove(attribute) {
    if (attribute.isInterleavedBufferAttribute) attribute = attribute.data;

    const data = buffers.get(attribute);

    if (data) {
      gl.deleteBuffer(data.buffer);

      buffers.delete(attribute);
    }
  }

  function update(attribute, bufferType) {
    if (attribute.isInterleavedBufferAttribute) attribute = attribute.data;

    const data = buffers.get(attribute);

    if (data === undefined) {
      buffers.set(attribute, createBuffer(attribute, bufferType));
    } else if (data.version < attribute.version) {
      updateBuffer(data.buffer, attribute, bufferType);

      data.version = attribute.version;
    }
  }

  return {
    get: get,
    remove: remove,
    update: update,
  };
}

function WebGLBindingStates(gl, attributes) {
  const maxVertexAttributes = gl.getParameter(34921);

  const bindingStates = {};

  const defaultState = createBindingState(null);
  let currentState = defaultState;

  function setup(object, material, program, geometry, index) {
    let updateBuffers = false;

    const state = getBindingState(geometry, program, material);

    if (currentState !== state) {
      currentState = state;
      bindVertexArrayObject(currentState.object);
    }

    updateBuffers = needsUpdate(geometry, index);

    if (updateBuffers) saveCache(geometry, index);

    if (object.isInstancedMesh === true) {
      updateBuffers = true;
    }

    if (index !== null) {
      attributes.update(index, 34963);
    }

    if (updateBuffers) {
      setupVertexAttributes(object, material, program, geometry);

      if (index !== null) {
        gl.bindBuffer(34963, attributes.get(index).buffer);
      }
    }
  }

  function createVertexArrayObject() {
    return gl.createVertexArray();
  }

  function bindVertexArrayObject(vao) {
    gl.bindVertexArray(vao);
  }

  function deleteVertexArrayObject(vao) {
    return gl.deleteVertexArray(vao);
  }

  function getBindingState(geometry, program, material) {
    const wireframe = material.wireframe === true;

    let programMap = bindingStates[geometry.id];

    if (programMap === undefined) {
      programMap = {};
      bindingStates[geometry.id] = programMap;
    }

    let stateMap = programMap[program.id];

    if (stateMap === undefined) {
      stateMap = {};
      programMap[program.id] = stateMap;
    }

    let state = stateMap[wireframe];

    if (state === undefined) {
      state = createBindingState(createVertexArrayObject());
      stateMap[wireframe] = state;
    }

    return state;
  }

  function createBindingState(vao) {
    const newAttributes = [];
    const enabledAttributes = [];
    const attributeDivisors = [];

    for (let i = 0; i < maxVertexAttributes; i++) {
      newAttributes[i] = 0;
      enabledAttributes[i] = 0;
      attributeDivisors[i] = 0;
    }

    return {
      // for backward compatibility on non-VAO support browser
      geometry: null,
      program: null,
      wireframe: false,

      newAttributes: newAttributes,
      enabledAttributes: enabledAttributes,
      attributeDivisors: attributeDivisors,
      object: vao,
      attributes: {},
      index: null,
    };
  }

  function needsUpdate(geometry, index) {
    const cachedAttributes = currentState.attributes;
    const geometryAttributes = geometry.attributes;

    let attributesNum = 0;

    for (const key in geometryAttributes) {
      const geometryAttribute = geometryAttributes[key];
      const cachedAttribute = cachedAttributes[key];

      if (cachedAttribute === undefined) return true;

      if (cachedAttribute.attribute !== geometryAttribute) return true;

      if (cachedAttribute.data !== geometryAttribute.data) return true;

      attributesNum++;
    }

    if (currentState.attributesNum !== attributesNum) return true;

    if (currentState.index !== index) return true;

    return false;
  }

  function saveCache(geometry, index) {
    const cache = {};
    const attributes = geometry.attributes;
    let attributesNum = 0;

    for (const key in attributes) {
      const attribute = attributes[key];

      const data = {};
      data.attribute = attribute;

      if (attribute.data) {
        data.data = attribute.data;
      }

      cache[key] = data;

      attributesNum++;
    }

    currentState.attributes = cache;
    currentState.attributesNum = attributesNum;

    currentState.index = index;
  }

  function initAttributes() {
    const newAttributes = currentState.newAttributes;
    newAttributes.fill(0);
  }

  function enableAttribute(attribute) {
    enableAttributeAndDivisor(attribute, 0);
  }

  function enableAttributeAndDivisor(attribute, meshPerAttribute) {
    const newAttributes = currentState.newAttributes;
    const enabledAttributes = currentState.enabledAttributes;
    const attributeDivisors = currentState.attributeDivisors;

    newAttributes[attribute] = 1;

    if (enabledAttributes[attribute] === 0) {
      gl.enableVertexAttribArray(attribute);
      enabledAttributes[attribute] = 1;
    }

    if (attributeDivisors[attribute] !== meshPerAttribute) {
      gl["vertexAttribDivisor"](attribute, meshPerAttribute);
      attributeDivisors[attribute] = meshPerAttribute;
    }
  }

  function disableUnusedAttributes() {
    const newAttributes = currentState.newAttributes;
    const enabledAttributes = currentState.enabledAttributes;

    for (let i = 0, il = enabledAttributes.length; i < il; i++) {
      if (enabledAttributes[i] !== newAttributes[i]) {
        gl.disableVertexAttribArray(i);
        enabledAttributes[i] = 0;
      }
    }
  }

  function vertexAttribPointer(index, size, type, normalized, stride, offset) {
    if (type === 5124 || type === 5125) {
      gl.vertexAttribIPointer(index, size, type, stride, offset);
    } else {
      gl.vertexAttribPointer(index, size, type, normalized, stride, offset);
    }
  }

  function setupVertexAttributes(object, material, program, geometry) {
    initAttributes();

    const geometryAttributes = geometry.attributes;

    const programAttributes = program.getAttributes();

    const materialDefaultAttributeValues = material.defaultAttributeValues;

    for (const name in programAttributes) {
      const programAttribute = programAttributes[name];

      if (programAttribute < 0) {
        continue;
      }
      const geometryAttribute = geometryAttributes[name];

      if (geometryAttribute !== undefined) {
        const normalized = geometryAttribute.normalized;
        const size = geometryAttribute.itemSize;

        const attribute = attributes.get(geometryAttribute);

        // TODO Attribute may not be available on context restore

        if (attribute === undefined) continue;

        const buffer = attribute.buffer;
        const type = attribute.type;
        const bytesPerElement = attribute.bytesPerElement;

        if (geometryAttribute.isInterleavedBufferAttribute) {
          const data = geometryAttribute.data;
          const stride = data.stride;
          const offset = geometryAttribute.offset;

          if (data && data.isInstancedInterleavedBuffer) {
            enableAttributeAndDivisor(programAttribute, data.meshPerAttribute);

            if (geometry._maxInstanceCount === undefined) {
              geometry._maxInstanceCount = data.meshPerAttribute * data.count;
            }
          } else {
            enableAttribute(programAttribute);
          }

          gl.bindBuffer(34962, buffer);
          vertexAttribPointer(
            programAttribute,
            size,
            type,
            normalized,
            stride * bytesPerElement,
            offset * bytesPerElement
          );
        } else {
          if (geometryAttribute.isInstancedBufferAttribute) {
            enableAttributeAndDivisor(
              programAttribute,
              geometryAttribute.meshPerAttribute
            );

            if (geometry._maxInstanceCount === undefined) {
              geometry._maxInstanceCount =
                geometryAttribute.meshPerAttribute * geometryAttribute.count;
            }
          } else {
            enableAttribute(programAttribute);
          }

          gl.bindBuffer(34962, buffer);
          vertexAttribPointer(programAttribute, size, type, normalized, 0, 0);
        }
      } else if (name === "instanceMatrix") {
        const attribute = attributes.get(object.instanceMatrix);

        // TODO Attribute may not be available on context restore
        if (attribute === undefined) continue;

        const buffer = attribute.buffer;
        const type = attribute.type;

        enableAttributeAndDivisor(programAttribute + 0, 1);
        enableAttributeAndDivisor(programAttribute + 1, 1);
        enableAttributeAndDivisor(programAttribute + 2, 1);
        enableAttributeAndDivisor(programAttribute + 3, 1);

        gl.bindBuffer(34962, buffer);

        gl.vertexAttribPointer(programAttribute + 0, 4, type, false, 64, 0);
        gl.vertexAttribPointer(programAttribute + 1, 4, type, false, 64, 16);
        gl.vertexAttribPointer(programAttribute + 2, 4, type, false, 64, 32);
        gl.vertexAttribPointer(programAttribute + 3, 4, type, false, 64, 48);
      } else if (name === "instanceColor") {
        const attribute = attributes.get(object.instanceColor);

        // TODO Attribute may not be available on context restore
        if (attribute === undefined) continue;

        const buffer = attribute.buffer;
        const type = attribute.type;

        enableAttributeAndDivisor(programAttribute, 1);

        gl.bindBuffer(34962, buffer);

        gl.vertexAttribPointer(programAttribute, 3, type, false, 12, 0);
      } else if (materialDefaultAttributeValues !== undefined) {
        const value = materialDefaultAttributeValues[name];

        if (value !== undefined) {
          switch (value.length) {
            case 2:
              gl.vertexAttrib2fv(programAttribute, value);
              break;

            case 3:
              gl.vertexAttrib3fv(programAttribute, value);
              break;

            case 4:
              gl.vertexAttrib4fv(programAttribute, value);
              break;

            default:
              gl.vertexAttrib1fv(programAttribute, value);
          }
        }
      }
    }

    disableUnusedAttributes();
  }

  function dispose() {
    reset();

    for (const geometryId in bindingStates) {
      const programMap = bindingStates[geometryId];

      for (const programId in programMap) {
        const stateMap = programMap[programId];

        for (const wireframe in stateMap) {
          deleteVertexArrayObject(stateMap[wireframe].object);

          delete stateMap[wireframe];
        }

        delete programMap[programId];
      }

      delete bindingStates[geometryId];
    }
  }

  function releaseStatesOfGeometry(geometry) {
    if (bindingStates[geometry.id] === undefined) return;

    const programMap = bindingStates[geometry.id];

    for (const programId in programMap) {
      const stateMap = programMap[programId];

      for (const wireframe in stateMap) {
        deleteVertexArrayObject(stateMap[wireframe].object);

        delete stateMap[wireframe];
      }

      delete programMap[programId];
    }

    delete bindingStates[geometry.id];
  }

  function releaseStatesOfProgram(program) {
    for (const geometryId in bindingStates) {
      const programMap = bindingStates[geometryId];

      if (programMap[program.id] === undefined) continue;

      const stateMap = programMap[program.id];

      for (const wireframe in stateMap) {
        deleteVertexArrayObject(stateMap[wireframe].object);

        delete stateMap[wireframe];
      }

      delete programMap[program.id];
    }
  }

  function reset() {
    resetDefaultState();

    if (currentState === defaultState) return;

    currentState = defaultState;
    bindVertexArrayObject(currentState.object);
  }

  // for backward-compatilibity

  function resetDefaultState() {
    defaultState.geometry = null;
    defaultState.program = null;
    defaultState.wireframe = false;
  }

  return {
    setup: setup,
    reset: reset,
    resetDefaultState: resetDefaultState,
    dispose: dispose,
    releaseStatesOfGeometry: releaseStatesOfGeometry,
    releaseStatesOfProgram: releaseStatesOfProgram,

    initAttributes: initAttributes,
    enableAttribute: enableAttribute,
    disableUnusedAttributes: disableUnusedAttributes,
  };
}

function WebGLCapabilities(gl, extensions, parameters) {
  let maxAnisotropy;

  function getMaxAnisotropy() {
    if (maxAnisotropy !== undefined) return maxAnisotropy;

    const extension = extensions.get("EXT_texture_filter_anisotropic");

    if (extension !== null) {
      maxAnisotropy = gl.getParameter(extension.MAX_TEXTURE_MAX_ANISOTROPY_EXT);
    } else {
      maxAnisotropy = 0;
    }

    return maxAnisotropy;
  }

  function getMaxPrecision(precision) {
    if (precision === "highp") {
      if (
        gl.getShaderPrecisionFormat(35633, 36338).precision > 0 &&
        gl.getShaderPrecisionFormat(35632, 36338).precision > 0
      ) {
        return "highp";
      }

      precision = "mediump";
    }

    if (precision === "mediump") {
      if (
        gl.getShaderPrecisionFormat(35633, 36337).precision > 0 &&
        gl.getShaderPrecisionFormat(35632, 36337).precision > 0
      ) {
        return "mediump";
      }
    }

    return "lowp";
  }

  /* eslint-disable no-undef */
  const isWebGL2 =
    (typeof WebGL2RenderingContext !== "undefined" &&
      gl instanceof WebGL2RenderingContext) ||
    (typeof WebGL2ComputeRenderingContext !== "undefined" &&
      gl instanceof WebGL2ComputeRenderingContext);
  /* eslint-enable no-undef */

  let precision = parameters.precision ?? "highp";
  const maxPrecision = getMaxPrecision(precision);

  if (maxPrecision !== precision) {
    console.warn(
      "THREE.WebGLRenderer:",
      precision,
      "not supported, using",
      maxPrecision,
      "instead."
    );
    precision = maxPrecision;
  }

  const logarithmicDepthBuffer = parameters.logarithmicDepthBuffer === true;

  const maxTextures = gl.getParameter(34930);
  const maxVertexTextures = gl.getParameter(35660);
  const maxTextureSize = gl.getParameter(3379);

  const maxAttributes = gl.getParameter(34921);
  const maxVertexUniforms = gl.getParameter(36347);
  const maxVaryings = gl.getParameter(36348);
  const maxFragmentUniforms = gl.getParameter(36349);

  const vertexTextures = maxVertexTextures > 0;
  const floatFragmentTextures = true;
  const floatVertexTextures = vertexTextures && floatFragmentTextures;

  const maxSamples = gl.getParameter(36183);

  return {
    isWebGL2: isWebGL2,

    getMaxAnisotropy: getMaxAnisotropy,
    getMaxPrecision: getMaxPrecision,

    precision: precision,
    logarithmicDepthBuffer: logarithmicDepthBuffer,

    maxTextures: maxTextures,
    maxVertexTextures: maxVertexTextures,
    maxTextureSize: maxTextureSize,

    maxAttributes: maxAttributes,
    maxVertexUniforms: maxVertexUniforms,
    maxVaryings: maxVaryings,
    maxFragmentUniforms: maxFragmentUniforms,

    vertexTextures: vertexTextures,
    floatFragmentTextures: floatFragmentTextures,
    floatVertexTextures: floatVertexTextures,

    maxSamples: maxSamples,
  };
}

function WebGLExtensions(gl) {
  const extensions = {};

  return {
    has: function (name) {
      if (extensions[name] !== undefined) {
        return extensions[name] !== null;
      }

      let extension;

      switch (name) {
        case "WEBGL_depth_texture":
          extension =
            gl.getExtension("WEBGL_depth_texture") ||
            gl.getExtension("MOZ_WEBGL_depth_texture") ||
            gl.getExtension("WEBKIT_WEBGL_depth_texture");
          break;

        case "EXT_texture_filter_anisotropic":
          extension =
            gl.getExtension("EXT_texture_filter_anisotropic") ||
            gl.getExtension("MOZ_EXT_texture_filter_anisotropic") ||
            gl.getExtension("WEBKIT_EXT_texture_filter_anisotropic");
          break;

        case "WEBGL_compressed_texture_s3tc":
          extension =
            gl.getExtension("WEBGL_compressed_texture_s3tc") ||
            gl.getExtension("MOZ_WEBGL_compressed_texture_s3tc") ||
            gl.getExtension("WEBKIT_WEBGL_compressed_texture_s3tc");
          break;

        case "WEBGL_compressed_texture_pvrtc":
          extension =
            gl.getExtension("WEBGL_compressed_texture_pvrtc") ||
            gl.getExtension("WEBKIT_WEBGL_compressed_texture_pvrtc");
          break;

        default:
          extension = gl.getExtension(name);
      }

      extensions[name] = extension;

      return extension !== null;
    },

    get: function (name) {
      if (!this.has(name)) {
        console.warn(
          "THREE.WebGLRenderer: " + name + " extension not supported."
        );
      }

      return extensions[name];
    },
  };
}

function WebGLInfo(gl) {
  const memory = {
    textures: 0,
  };

  const render = {
    frame: 0,
    calls: 0,
    triangles: 0,
    points: 0,
    lines: 0,
  };

  function update(count, mode, instanceCount) {
    render.calls++;

    switch (mode) {
      case 4:
        render.triangles += instanceCount * (count / 3);
        break;

      case 1:
        render.lines += instanceCount * (count / 2);
        break;

      case 3:
        render.lines += instanceCount * (count - 1);
        break;

      case 2:
        render.lines += instanceCount * count;
        break;

      case 0:
        render.points += instanceCount * count;
        break;

      default:
        console.error("THREE.WebGLInfo: Unknown draw mode:", mode);
        break;
    }
  }

  function reset() {
    render.frame++;
    render.calls = 0;
    render.triangles = 0;
    render.points = 0;
    render.lines = 0;
  }

  return {
    memory: memory,
    render: render,
    autoReset: true,
    reset: reset,
    update: update,
  };
}

function WebGLProperties() {
  let properties = new WeakMap();

  function get(object) {
    let map = properties.get(object);

    if (map === undefined) {
      map = {};
      properties.set(object, map);
    }

    return map;
  }

  function remove(object) {
    properties.delete(object);
  }

  function update(object, key, value) {
    properties.get(object)[key] = value;
  }

  function dispose() {
    properties = new WeakMap();
  }

  return {
    get: get,
    remove: remove,
    update: update,
    dispose: dispose,
  };
}

function WebGLRenderState() {
  const lightsArray = [];
  const shadowsArray = [];

  function init() {
    lightsArray.length = 0;
    shadowsArray.length = 0;
  }

  function pushLight(light) {
    lightsArray.push(light);
  }

  function pushShadow(shadowLight) {
    shadowsArray.push(shadowLight);
  }

  const state = {
    lightsArray: lightsArray,
    shadowsArray: shadowsArray,
  };

  return {
    init: init,
    state: state,

    pushLight: pushLight,
    pushShadow: pushShadow,
  };
}

function WebGLRenderStates() {
  let renderStates = new WeakMap();

  function get(scene, renderCallDepth = 0) {
    let renderState;

    if (renderStates.has(scene) === false) {
      renderState = new WebGLRenderState();
      renderStates.set(scene, [renderState]);
    } else {
      if (renderCallDepth >= renderStates.get(scene).length) {
        renderState = new WebGLRenderState();
        renderStates.get(scene).push(renderState);
      } else {
        renderState = renderStates.get(scene)[renderCallDepth];
      }
    }

    return renderState;
  }

  function dispose() {
    renderStates = new WeakMap();
  }

  return {
    get: get,
    dispose: dispose,
  };
}

function WebGLState(gl) {
  function ColorBuffer() {
    let locked = false;

    const color = new Vector4();
    let currentColorMask = null;
    const currentColorClear = new Vector4(0, 0, 0, 0);

    return {
      setMask: function (colorMask) {
        if (currentColorMask !== colorMask && !locked) {
          gl.colorMask(colorMask, colorMask, colorMask, colorMask);
          currentColorMask = colorMask;
        }
      },

      setLocked: function (lock) {
        locked = lock;
      },

      setClear: function (r, g, b, a, premultipliedAlpha) {
        if (premultipliedAlpha === true) {
          r *= a;
          g *= a;
          b *= a;
        }

        color.set(r, g, b, a);

        if (currentColorClear.equals(color) === false) {
          gl.clearColor(r, g, b, a);
          currentColorClear.copy(color);
        }
      },

      reset: function () {
        locked = false;

        currentColorMask = null;
        currentColorClear.set(-1, 0, 0, 0); // set to invalid state
      },
    };
  }

  function DepthBuffer() {
    let locked = false;

    let currentDepthMask = null;
    let currentDepthFunc = null;
    let currentDepthClear = null;

    return {
      setTest: function (depthTest) {
        if (depthTest) {
          enable(2929);
        } else {
          disable(2929);
        }
      },

      setMask: function (depthMask) {
        if (currentDepthMask !== depthMask && !locked) {
          gl.depthMask(depthMask);
          currentDepthMask = depthMask;
        }
      },

      setFunc: function (depthFunc) {
        if (currentDepthFunc !== depthFunc) {
          if (depthFunc) {
            switch (depthFunc) {
              case NeverDepth:
                gl.depthFunc(512);
                break;

              case AlwaysDepth:
                gl.depthFunc(519);
                break;

              case LessDepth:
                gl.depthFunc(513);
                break;

              case LessEqualDepth:
                gl.depthFunc(515);
                break;

              case EqualDepth:
                gl.depthFunc(514);
                break;

              case GreaterEqualDepth:
                gl.depthFunc(518);
                break;

              case GreaterDepth:
                gl.depthFunc(516);
                break;

              case NotEqualDepth:
                gl.depthFunc(517);
                break;

              default:
                gl.depthFunc(515);
            }
          } else {
            gl.depthFunc(515);
          }

          currentDepthFunc = depthFunc;
        }
      },

      setLocked: function (lock) {
        locked = lock;
      },

      setClear: function (depth) {
        if (currentDepthClear !== depth) {
          gl.clearDepth(depth);
          currentDepthClear = depth;
        }
      },

      reset: function () {
        locked = false;

        currentDepthMask = null;
        currentDepthFunc = null;
        currentDepthClear = null;
      },
    };
  }

  function StencilBuffer() {
    let locked = false;

    let currentStencilMask = null;
    let currentStencilFunc = null;
    let currentStencilRef = null;
    let currentStencilFuncMask = null;
    let currentStencilFail = null;
    let currentStencilZFail = null;
    let currentStencilZPass = null;
    let currentStencilClear = null;

    return {
      setTest: function (stencilTest) {
        if (!locked) {
          if (stencilTest) {
            enable(2960);
          } else {
            disable(2960);
          }
        }
      },

      setMask: function (stencilMask) {
        if (currentStencilMask !== stencilMask && !locked) {
          gl.stencilMask(stencilMask);
          currentStencilMask = stencilMask;
        }
      },

      setFunc: function (stencilFunc, stencilRef, stencilMask) {
        if (
          currentStencilFunc !== stencilFunc ||
          currentStencilRef !== stencilRef ||
          currentStencilFuncMask !== stencilMask
        ) {
          gl.stencilFunc(stencilFunc, stencilRef, stencilMask);

          currentStencilFunc = stencilFunc;
          currentStencilRef = stencilRef;
          currentStencilFuncMask = stencilMask;
        }
      },

      setOp: function (stencilFail, stencilZFail, stencilZPass) {
        if (
          currentStencilFail !== stencilFail ||
          currentStencilZFail !== stencilZFail ||
          currentStencilZPass !== stencilZPass
        ) {
          gl.stencilOp(stencilFail, stencilZFail, stencilZPass);

          currentStencilFail = stencilFail;
          currentStencilZFail = stencilZFail;
          currentStencilZPass = stencilZPass;
        }
      },

      setLocked: function (lock) {
        locked = lock;
      },

      setClear: function (stencil) {
        if (currentStencilClear !== stencil) {
          gl.clearStencil(stencil);
          currentStencilClear = stencil;
        }
      },

      reset: function () {
        locked = false;

        currentStencilMask = null;
        currentStencilFunc = null;
        currentStencilRef = null;
        currentStencilFuncMask = null;
        currentStencilFail = null;
        currentStencilZFail = null;
        currentStencilZPass = null;
        currentStencilClear = null;
      },
    };
  }

  const colorBuffer = new ColorBuffer();
  const depthBuffer = new DepthBuffer();
  const stencilBuffer = new StencilBuffer();

  let enabledCapabilities = {};

  let currentProgram = null;

  let currentBlendingEnabled = null;
  let currentBlending = null;
  let currentBlendEquation = null;
  let currentBlendSrc = null;
  let currentBlendDst = null;
  let currentBlendEquationAlpha = null;
  let currentBlendSrcAlpha = null;
  let currentBlendDstAlpha = null;
  let currentPremultipledAlpha = false;

  let currentFlipSided = null;
  let currentCullFace = null;

  let currentPolygonOffsetFactor = null;
  let currentPolygonOffsetUnits = null;

  const maxTextures = gl.getParameter(35661);

  const glVersion = gl.getParameter(7938);

  console.info("GL Version: ", glVersion);

  let currentTextureSlot = null;
  let currentBoundTextures = {};

  const currentScissor = new Vector4();
  const currentViewport = new Vector4();

  function createTexture(type, target, count) {
    const data = new Uint8Array(4); // 4 is required to match default unpack alignment of 4.
    const texture = gl.createTexture();

    gl.bindTexture(type, texture);
    gl.texParameteri(type, 10241, 9728);
    gl.texParameteri(type, 10240, 9728);

    for (let i = 0; i < count; i++) {
      gl.texImage2D(target + i, 0, 6408, 1, 1, 0, 6408, 5121, data);
    }

    return texture;
  }

  const emptyTextures = {};
  emptyTextures[3553] = createTexture(3553, 3553, 1);
  emptyTextures[34067] = createTexture(34067, 34069, 6);

  // init

  colorBuffer.setClear(0, 0, 0, 1);
  depthBuffer.setClear(1);
  stencilBuffer.setClear(0);

  enable(2929);
  depthBuffer.setFunc(LessEqualDepth);

  setFlipSided(false);
  setCullFace(CullFaceBack);
  enable(2884);

  setBlending(NoBlending);

  //

  function enable(id) {
    if (enabledCapabilities[id] !== true) {
      gl.enable(id);
      enabledCapabilities[id] = true;
    }
  }

  function disable(id) {
    if (enabledCapabilities[id] !== false) {
      gl.disable(id);
      enabledCapabilities[id] = false;
    }
  }

  function useProgram(program) {
    if (currentProgram !== program) {
      gl.useProgram(program);

      currentProgram = program;

      return true;
    }

    return false;
  }

  const equationToGL = {
    [AddEquation]: 32774,
    [SubtractEquation]: 32778,
    [ReverseSubtractEquation]: 32779,
  };

  equationToGL[MinEquation] = 32775;
  equationToGL[MaxEquation] = 32776;

  const factorToGL = {
    [ZeroFactor]: 0,
    [OneFactor]: 1,
    [SrcColorFactor]: 768,
    [SrcAlphaFactor]: 770,
    [SrcAlphaSaturateFactor]: 776,
    [DstColorFactor]: 774,
    [DstAlphaFactor]: 772,
    [OneMinusSrcColorFactor]: 769,
    [OneMinusSrcAlphaFactor]: 771,
    [OneMinusDstColorFactor]: 775,
    [OneMinusDstAlphaFactor]: 773,
  };

  function setBlending(
    blending,
    blendEquation,
    blendSrc,
    blendDst,
    blendEquationAlpha,
    blendSrcAlpha,
    blendDstAlpha,
    premultipliedAlpha
  ) {
    if (blending === NoBlending) {
      if (currentBlendingEnabled) {
        disable(3042);
        currentBlendingEnabled = false;
      }

      return;
    }

    if (!currentBlendingEnabled) {
      enable(3042);
      currentBlendingEnabled = true;
    }

    if (blending !== CustomBlending) {
      if (
        blending !== currentBlending ||
        premultipliedAlpha !== currentPremultipledAlpha
      ) {
        if (
          currentBlendEquation !== AddEquation ||
          currentBlendEquationAlpha !== AddEquation
        ) {
          gl.blendEquation(32774);

          currentBlendEquation = AddEquation;
          currentBlendEquationAlpha = AddEquation;
        }

        if (premultipliedAlpha) {
          switch (blending) {
            case NormalBlending:
              gl.blendFuncSeparate(1, 771, 1, 771);
              break;

            case AdditiveBlending:
              gl.blendFunc(1, 1);
              break;

            case SubtractiveBlending:
              gl.blendFuncSeparate(0, 0, 769, 771);
              break;

            case MultiplyBlending:
              gl.blendFuncSeparate(0, 768, 0, 770);
              break;

            default:
              console.error("THREE.WebGLState: Invalid blending: ", blending);
              break;
          }
        } else {
          switch (blending) {
            case NormalBlending:
              gl.blendFuncSeparate(770, 771, 1, 771);
              break;

            case AdditiveBlending:
              gl.blendFunc(770, 1);
              break;

            case SubtractiveBlending:
              gl.blendFunc(0, 769);
              break;

            case MultiplyBlending:
              gl.blendFunc(0, 768);
              break;

            default:
              console.error("THREE.WebGLState: Invalid blending: ", blending);
              break;
          }
        }

        currentBlendSrc = null;
        currentBlendDst = null;
        currentBlendSrcAlpha = null;
        currentBlendDstAlpha = null;

        currentBlending = blending;
        currentPremultipledAlpha = premultipliedAlpha;
      }

      return;
    }

    // custom blending

    blendEquationAlpha = blendEquationAlpha || blendEquation;
    blendSrcAlpha = blendSrcAlpha || blendSrc;
    blendDstAlpha = blendDstAlpha || blendDst;

    if (
      blendEquation !== currentBlendEquation ||
      blendEquationAlpha !== currentBlendEquationAlpha
    ) {
      gl.blendEquationSeparate(
        equationToGL[blendEquation],
        equationToGL[blendEquationAlpha]
      );

      currentBlendEquation = blendEquation;
      currentBlendEquationAlpha = blendEquationAlpha;
    }

    if (
      blendSrc !== currentBlendSrc ||
      blendDst !== currentBlendDst ||
      blendSrcAlpha !== currentBlendSrcAlpha ||
      blendDstAlpha !== currentBlendDstAlpha
    ) {
      gl.blendFuncSeparate(
        factorToGL[blendSrc],
        factorToGL[blendDst],
        factorToGL[blendSrcAlpha],
        factorToGL[blendDstAlpha]
      );

      currentBlendSrc = blendSrc;
      currentBlendDst = blendDst;
      currentBlendSrcAlpha = blendSrcAlpha;
      currentBlendDstAlpha = blendDstAlpha;
    }

    currentBlending = blending;
    currentPremultipledAlpha = null;
  }

  function setMaterial(material, frontFaceCW) {
    material.side === DoubleSide ? disable(2884) : enable(2884);

    let flipSided = material.side === BackSide;
    if (frontFaceCW) flipSided = !flipSided;

    setFlipSided(flipSided);

    material.blending === NormalBlending && material.transparent === false
      ? setBlending(NoBlending)
      : setBlending(
          material.blending,
          material.blendEquation,
          material.blendSrc,
          material.blendDst,
          material.blendEquationAlpha,
          material.blendSrcAlpha,
          material.blendDstAlpha,
          material.premultipliedAlpha
        );

    depthBuffer.setFunc(material.depthFunc);
    depthBuffer.setTest(material.depthTest);
    depthBuffer.setMask(material.depthWrite);
    colorBuffer.setMask(material.colorWrite);

    const stencilWrite = material.stencilWrite;
    stencilBuffer.setTest(stencilWrite);
    if (stencilWrite) {
      stencilBuffer.setMask(material.stencilWriteMask);
      stencilBuffer.setFunc(
        material.stencilFunc,
        material.stencilRef,
        material.stencilFuncMask
      );
      stencilBuffer.setOp(
        material.stencilFail,
        material.stencilZFail,
        material.stencilZPass
      );
    }

    setPolygonOffset(
      material.polygonOffset,
      material.polygonOffsetFactor,
      material.polygonOffsetUnits
    );
  }

  //

  function setFlipSided(flipSided) {
    if (currentFlipSided !== flipSided) {
      if (flipSided) {
        gl.frontFace(2304);
      } else {
        gl.frontFace(2305);
      }

      currentFlipSided = flipSided;
    }
  }

  function setCullFace(cullFace) {
    if (cullFace !== CullFaceNone) {
      enable(2884);

      if (cullFace !== currentCullFace) {
        if (cullFace === CullFaceBack) {
          gl.cullFace(1029);
        } else if (cullFace === CullFaceFront) {
          gl.cullFace(1028);
        } else {
          gl.cullFace(1032);
        }
      }
    } else {
      disable(2884);
    }

    currentCullFace = cullFace;
  }

  function setPolygonOffset(polygonOffset, factor, units) {
    if (polygonOffset) {
      enable(32823);

      if (
        currentPolygonOffsetFactor !== factor ||
        currentPolygonOffsetUnits !== units
      ) {
        gl.polygonOffset(factor, units);

        currentPolygonOffsetFactor = factor;
        currentPolygonOffsetUnits = units;
      }
    } else {
      disable(32823);
    }
  }

  function setScissorTest(scissorTest) {
    if (scissorTest) {
      enable(3089);
    } else {
      disable(3089);
    }
  }

  // texture

  function activeTexture(webglSlot) {
    if (webglSlot === undefined) webglSlot = 33984 + maxTextures - 1;

    if (currentTextureSlot !== webglSlot) {
      gl.activeTexture(webglSlot);
      currentTextureSlot = webglSlot;
    }
  }

  function bindTexture(webglType, webglTexture) {
    if (currentTextureSlot === null) {
      activeTexture();
    }

    let boundTexture = currentBoundTextures[currentTextureSlot];

    if (boundTexture === undefined) {
      boundTexture = { type: undefined, texture: undefined };
      currentBoundTextures[currentTextureSlot] = boundTexture;
    }

    if (
      boundTexture.type !== webglType ||
      boundTexture.texture !== webglTexture
    ) {
      gl.bindTexture(webglType, webglTexture || emptyTextures[webglType]);

      boundTexture.type = webglType;
      boundTexture.texture = webglTexture;
    }
  }

  function unbindTexture() {
    const boundTexture = currentBoundTextures[currentTextureSlot];

    if (boundTexture !== undefined && boundTexture.type !== undefined) {
      gl.bindTexture(boundTexture.type, null);

      boundTexture.type = undefined;
      boundTexture.texture = undefined;
    }
  }

  function compressedTexImage2D() {
    try {
      gl.compressedTexImage2D.apply(gl, arguments);
    } catch (error) {
      console.error("THREE.WebGLState:", error);
    }
  }

  function texImage2D() {
    try {
      gl.texImage2D.apply(gl, arguments);
    } catch (error) {
      console.error("THREE.WebGLState:", error);
    }
  }

  function texImage3D() {
    try {
      gl.texImage3D.apply(gl, arguments);
    } catch (error) {
      console.error("THREE.WebGLState:", error);
    }
  }

  //

  function scissor(scissor) {
    if (currentScissor.equals(scissor) === false) {
      gl.scissor(scissor.x, scissor.y, scissor.z, scissor.w);
      currentScissor.copy(scissor);
    }
  }

  function viewport(viewport) {
    if (currentViewport.equals(viewport) === false) {
      gl.viewport(viewport.x, viewport.y, viewport.z, viewport.w);
      currentViewport.copy(viewport);
    }
  }

  //

  function reset() {
    enabledCapabilities = {};

    currentTextureSlot = null;
    currentBoundTextures = {};

    currentProgram = null;

    currentBlendingEnabled = null;
    currentBlending = null;
    currentBlendEquation = null;
    currentBlendSrc = null;
    currentBlendDst = null;
    currentBlendEquationAlpha = null;
    currentBlendSrcAlpha = null;
    currentBlendDstAlpha = null;
    currentPremultipledAlpha = false;

    currentFlipSided = null;
    currentCullFace = null;

    currentPolygonOffsetFactor = null;
    currentPolygonOffsetUnits = null;

    colorBuffer.reset();
    depthBuffer.reset();
    stencilBuffer.reset();
  }

  return {
    buffers: {
      color: colorBuffer,
      depth: depthBuffer,
      stencil: stencilBuffer,
    },

    enable: enable,
    disable: disable,

    useProgram: useProgram,

    setBlending: setBlending,
    setMaterial: setMaterial,

    setFlipSided: setFlipSided,
    setCullFace: setCullFace,

    setPolygonOffset: setPolygonOffset,

    setScissorTest: setScissorTest,

    activeTexture: activeTexture,
    bindTexture: bindTexture,
    unbindTexture: unbindTexture,
    compressedTexImage2D: compressedTexImage2D,
    texImage2D: texImage2D,
    texImage3D: texImage3D,

    scissor: scissor,
    viewport: viewport,

    reset: reset,
  };
}

function WebGLTextures(
  _gl,
  extensions,
  state,
  properties,
  capabilities,
  utils,
  info
) {
  const maxTextures = capabilities.maxTextures;
  const maxTextureSize = capabilities.maxTextureSize;
  const maxSamples = capabilities.maxSamples;

  let _canvas;

  let useOffscreenCanvas = false;

  try {
    useOffscreenCanvas =
      typeof OffscreenCanvas !== "undefined" &&
      new OffscreenCanvas(1, 1).getContext("2d") !== null;
  } catch {
    // Ignore any errors
  }

  function createCanvas(width, height) {
    // Use OffscreenCanvas when available. Specially needed in web workers

    return useOffscreenCanvas
      ? new OffscreenCanvas(width, height)
      : document.createElementNS("http://www.w3.org/1999/xhtml", "canvas");
  }

  function resizeImage(image, needsNewCanvas, maxSize) {
    let scale = 1;

    // handle case if texture exceeds max size

    if (image.width > maxSize || image.height > maxSize) {
      scale = maxSize / Math.max(image.width, image.height);
    }

    // only perform resize if necessary

    if (scale < 1) {
      // only perform resize for certain image types

      if (
        (typeof HTMLImageElement !== "undefined" &&
          image instanceof HTMLImageElement) ||
        (typeof HTMLCanvasElement !== "undefined" &&
          image instanceof HTMLCanvasElement) ||
        (typeof ImageBitmap !== "undefined" && image instanceof ImageBitmap)
      ) {
        const floor = Math.floor;

        const width = floor(scale * image.width);
        const height = floor(scale * image.height);

        if (_canvas === undefined) _canvas = createCanvas(width, height);

        // cube textures can't reuse the same canvas

        const canvas = needsNewCanvas ? createCanvas(width, height) : _canvas;

        canvas.width = width;
        canvas.height = height;

        const context = canvas.getContext("2d");
        context.drawImage(image, 0, 0, width, height);

        console.warn(
          "THREE.WebGLRenderer: Texture has been resized from (" +
            image.width +
            "x" +
            image.height +
            ") to (" +
            width +
            "x" +
            height +
            ")."
        );

        return canvas;
      } else {
        if ("data" in image) {
          console.warn(
            "THREE.WebGLRenderer: Image in DataTexture is too big (" +
              image.width +
              "x" +
              image.height +
              ")."
          );
        }

        return image;
      }
    }

    return image;
  }

  function textureNeedsGenerateMipmaps(texture, supportsMips) {
    return (
      texture.generateMipmaps &&
      supportsMips &&
      texture.minFilter !== NearestFilter &&
      texture.minFilter !== LinearFilter
    );
  }

  function generateMipmap(target, texture, width, height) {
    _gl.generateMipmap(target);

    const textureProperties = properties.get(texture);

    textureProperties.__maxMipLevel = Math.log2(Math.max(width, height));
  }

  function getInternalFormat(internalFormatName, glFormat, glType) {
    if (internalFormatName !== null) {
      if (_gl[internalFormatName] !== undefined) return _gl[internalFormatName];

      console.warn(
        "THREE.WebGLRenderer: Attempt to use non-existing WebGL internal format '" +
          internalFormatName +
          "'"
      );
    }

    let internalFormat = glFormat;

    if (glFormat === 6403) {
      if (glType === 5126) internalFormat = 33326;
      if (glType === 5131) internalFormat = 33325;
      if (glType === 5121) internalFormat = 33321;
    }

    if (glFormat === 6407) {
      if (glType === 5126) internalFormat = 34837;
      if (glType === 5131) internalFormat = 34843;
      if (glType === 5121) internalFormat = 32849;
    }

    if (glFormat === 6408) {
      if (glType === 5126) internalFormat = 34836;
      if (glType === 5131) internalFormat = 34842;
      if (glType === 5121) internalFormat = 32856;
    }

    if (
      internalFormat === 33325 ||
      internalFormat === 33326 ||
      internalFormat === 34842 ||
      internalFormat === 34836
    ) {
      extensions.get("EXT_color_buffer_float");
    }

    return internalFormat;
  }

  // Fallback filters for non-power-of-2 textures

  function filterFallback(f) {
    if (
      f === NearestFilter ||
      f === NearestMipMapNearestFilter ||
      f === NearestMipMapLinearFilter
    ) {
      return 9728;
    }

    return 9729;
  }

  //

  function onTextureDispose(event) {
    const texture = event.target;

    texture.removeEventListener("dispose", onTextureDispose);

    deallocateTexture(texture);

    info.memory.textures--;
  }

  function onRenderTargetDispose(event) {
    const renderTarget = event.target;

    renderTarget.removeEventListener("dispose", onRenderTargetDispose);

    deallocateRenderTarget(renderTarget);

    info.memory.textures--;
  }

  //

  function deallocateTexture(texture) {
    const textureProperties = properties.get(texture);

    if (textureProperties.__webglInit === undefined) return;

    _gl.deleteTexture(textureProperties.__webglTexture);

    properties.remove(texture);
  }

  function deallocateRenderTarget(renderTarget) {
    const renderTargetProperties = properties.get(renderTarget);
    const textureProperties = properties.get(renderTarget.texture);

    if (!renderTarget) return;

    if (textureProperties.__webglTexture !== undefined) {
      _gl.deleteTexture(textureProperties.__webglTexture);
    }

    if (renderTarget.depthTexture) {
      renderTarget.depthTexture.dispose();
    }

    _gl.deleteFramebuffer(renderTargetProperties.__webglFramebuffer);
    if (renderTargetProperties.__webglDepthbuffer)
      _gl.deleteRenderbuffer(renderTargetProperties.__webglDepthbuffer);
    if (renderTargetProperties.__webglMultisampledFramebuffer)
      _gl.deleteFramebuffer(
        renderTargetProperties.__webglMultisampledFramebuffer
      );
    if (renderTargetProperties.__webglColorRenderbuffer)
      _gl.deleteRenderbuffer(renderTargetProperties.__webglColorRenderbuffer);
    if (renderTargetProperties.__webglDepthRenderbuffer)
      _gl.deleteRenderbuffer(renderTargetProperties.__webglDepthRenderbuffer);

    properties.remove(renderTarget.texture);
    properties.remove(renderTarget);
  }

  //

  let textureUnits = 0;

  function resetTextureUnits() {
    textureUnits = 0;
  }

  function allocateTextureUnit() {
    const textureUnit = textureUnits;

    if (textureUnit >= maxTextures) {
      console.warn(
        "THREE.WebGLTextures: Trying to use " +
          textureUnit +
          " texture units while this GPU supports only " +
          maxTextures
      );
    }

    textureUnits += 1;

    return textureUnit;
  }

  //

  function setTexture2D(texture, slot) {
    const textureProperties = properties.get(texture);

    if (
      texture.version > 0 &&
      textureProperties.__version !== texture.version
    ) {
      const image = texture.image;

      if (image === undefined) {
        console.warn(
          "THREE.WebGLRenderer: Texture marked for update but image is undefined"
        );
      } else if (image.complete === false) {
        console.warn(
          "THREE.WebGLRenderer: Texture marked for update but image is incomplete"
        );
      } else {
        uploadTexture(textureProperties, texture, slot);
        return;
      }
    }

    state.activeTexture(33984 + slot);
    state.bindTexture(3553, textureProperties.__webglTexture);
  }

  function setTexture2DArray(texture, slot) {
    const textureProperties = properties.get(texture);

    if (
      texture.version > 0 &&
      textureProperties.__version !== texture.version
    ) {
      uploadTexture(textureProperties, texture, slot);
      return;
    }

    state.activeTexture(33984 + slot);
    state.bindTexture(35866, textureProperties.__webglTexture);
  }

  function setTexture3D(texture, slot) {
    const textureProperties = properties.get(texture);

    if (
      texture.version > 0 &&
      textureProperties.__version !== texture.version
    ) {
      uploadTexture(textureProperties, texture, slot);
      return;
    }

    state.activeTexture(33984 + slot);
    state.bindTexture(32879, textureProperties.__webglTexture);
  }

  const wrappingToGL = {
    [RepeatWrapping]: 10497,
    [ClampToEdgeWrapping]: 33071,
    [MirroredRepeatWrapping]: 33648,
  };

  const filterToGL = {
    [NearestFilter]: 9728,
    [NearestMipMapNearestFilter]: 9984,
    [NearestMipMapLinearFilter]: 9986,

    [LinearFilter]: 9729,
    [LinearMipMapNearestFilter]: 9985,
    [LinearMipMapLinearFilter]: 9987,
  };

  function setTextureParameters(textureType, texture, supportsMips) {
    if (supportsMips) {
      _gl.texParameteri(textureType, 10242, wrappingToGL[texture.wrapS]);
      _gl.texParameteri(textureType, 10243, wrappingToGL[texture.wrapT]);

      if (textureType === 32879 || textureType === 35866) {
        _gl.texParameteri(textureType, 32882, wrappingToGL[texture.wrapR]);
      }

      _gl.texParameteri(textureType, 10240, filterToGL[texture.magFilter]);
      _gl.texParameteri(textureType, 10241, filterToGL[texture.minFilter]);
    } else {
      _gl.texParameteri(textureType, 10242, 33071);
      _gl.texParameteri(textureType, 10243, 33071);

      if (textureType === 32879 || textureType === 35866) {
        _gl.texParameteri(textureType, 32882, 33071);
      }

      if (
        texture.wrapS !== ClampToEdgeWrapping ||
        texture.wrapT !== ClampToEdgeWrapping
      ) {
        console.warn(
          "THREE.WebGLRenderer: Texture is not power of two. Texture.wrapS and Texture.wrapT should be set to THREE.ClampToEdgeWrapping."
        );
      }

      _gl.texParameteri(textureType, 10240, filterFallback(texture.magFilter));
      _gl.texParameteri(textureType, 10241, filterFallback(texture.minFilter));

      if (
        texture.minFilter !== NearestFilter &&
        texture.minFilter !== LinearFilter
      ) {
        console.warn(
          "THREE.WebGLRenderer: Texture is not power of two. Texture.minFilter should be set to THREE.NearestFilter or THREE.LinearFilter."
        );
      }
    }

    const extension = extensions.get("EXT_texture_filter_anisotropic");

    if (extension) {
      if (
        texture.type === FloatType &&
        extensions.get("OES_texture_float_linear") === null
      )
        return;

      if (
        texture.anisotropy > 1 ||
        properties.get(texture).__currentAnisotropy
      ) {
        _gl.texParameterf(
          textureType,
          extension.TEXTURE_MAX_ANISOTROPY_EXT,
          Math.min(texture.anisotropy, capabilities.getMaxAnisotropy())
        );
        properties.get(texture).__currentAnisotropy = texture.anisotropy;
      }
    }
  }

  function initTexture(textureProperties, texture) {
    if (textureProperties.__webglInit === undefined) {
      textureProperties.__webglInit = true;

      texture.addEventListener("dispose", onTextureDispose);

      textureProperties.__webglTexture = _gl.createTexture();

      info.memory.textures++;
    }
  }

  function uploadTexture(textureProperties, texture, slot) {
    let textureType = 3553;

    if (texture.isDataTexture2DArray) textureType = 35866;

    initTexture(textureProperties, texture);

    state.activeTexture(33984 + slot);
    state.bindTexture(textureType, textureProperties.__webglTexture);

    _gl.pixelStorei(37440, texture.flipY);
    _gl.pixelStorei(37441, texture.premultiplyAlpha);
    _gl.pixelStorei(3317, texture.unpackAlignment);

    const image = resizeImage(texture.image, false, maxTextureSize);

    const supportsMips = true,
      glFormat = utils.convert(texture.format);

    let glType = utils.convert(texture.type),
      glInternalFormat = getInternalFormat(
        texture.internalFormat,
        glFormat,
        glType
      );

    setTextureParameters(textureType, texture, supportsMips);

    let mipmap;
    const mipmaps = texture.mipmaps;

    if (texture.isDepthTexture) {
      // populate depth texture with dummy data

      glInternalFormat = 6402;

      if (texture.type === FloatType) {
        glInternalFormat = 36012;
      } else if (texture.type === UnsignedIntType) {
        glInternalFormat = 33190;
      } else if (texture.type === UnsignedInt248Type) {
        glInternalFormat = 35056;
      } else {
        glInternalFormat = 33189; // WebGL2 requires sized internalformat for glTexImage2D
      }

      // validation checks for WebGL 1

      if (texture.format === DepthFormat && glInternalFormat === 6402) {
        // The error INVALID_OPERATION is generated by texImage2D if format and internalformat are
        // DEPTH_COMPONENT and type is not UNSIGNED_SHORT or UNSIGNED_INT
        // (https://www.khronos.org/registry/webgl/extensions/WEBGL_depth_texture/)
        if (
          texture.type !== UnsignedShortType &&
          texture.type !== UnsignedIntType
        ) {
          console.warn(
            "THREE.WebGLRenderer: Use UnsignedShortType or UnsignedIntType for DepthFormat DepthTexture."
          );

          texture.type = UnsignedShortType;
          glType = utils.convert(texture.type);
        }
      }

      if (texture.format === DepthStencilFormat && glInternalFormat === 6402) {
        // Depth stencil textures need the DEPTH_STENCIL internal format
        // (https://www.khronos.org/registry/webgl/extensions/WEBGL_depth_texture/)
        glInternalFormat = 34041;

        // The error INVALID_OPERATION is generated by texImage2D if format and internalformat are
        // DEPTH_STENCIL and type is not UNSIGNED_INT_24_8_WEBGL.
        // (https://www.khronos.org/registry/webgl/extensions/WEBGL_depth_texture/)
        if (texture.type !== UnsignedInt248Type) {
          console.warn(
            "THREE.WebGLRenderer: Use UnsignedInt248Type for DepthStencilFormat DepthTexture."
          );

          texture.type = UnsignedInt248Type;
          glType = utils.convert(texture.type);
        }
      }

      //

      state.texImage2D(
        3553,
        0,
        glInternalFormat,
        image.width,
        image.height,
        0,
        glFormat,
        glType,
        null
      );
    } else if (texture.isDataTexture) {
      // use manually created mipmaps if available
      // if there are no manual mipmaps
      // set 0 level mipmap and then use GL to generate other mipmap levels

      if (mipmaps.length > 0 && supportsMips) {
        for (let i = 0, il = mipmaps.length; i < il; i++) {
          mipmap = mipmaps[i];
          state.texImage2D(
            3553,
            i,
            glInternalFormat,
            mipmap.width,
            mipmap.height,
            0,
            glFormat,
            glType,
            mipmap.data
          );
        }

        texture.generateMipmaps = false;
        textureProperties.__maxMipLevel = mipmaps.length - 1;
      } else {
        state.texImage2D(
          3553,
          0,
          glInternalFormat,
          image.width,
          image.height,
          0,
          glFormat,
          glType,
          image.data
        );
        textureProperties.__maxMipLevel = 0;
      }
    } else if (texture.isCompressedTexture) {
      for (let i = 0, il = mipmaps.length; i < il; i++) {
        mipmap = mipmaps[i];

        if (texture.format !== RGBAFormat && texture.format !== RGBFormat) {
          if (glFormat !== null) {
            state.compressedTexImage2D(
              3553,
              i,
              glInternalFormat,
              mipmap.width,
              mipmap.height,
              0,
              mipmap.data
            );
          } else {
            console.warn(
              "THREE.WebGLRenderer: Attempt to load unsupported compressed texture format in .uploadTexture()"
            );
          }
        } else {
          state.texImage2D(
            3553,
            i,
            glInternalFormat,
            mipmap.width,
            mipmap.height,
            0,
            glFormat,
            glType,
            mipmap.data
          );
        }
      }

      textureProperties.__maxMipLevel = mipmaps.length - 1;
    } else if (texture.isDataTexture2DArray) {
      state.texImage3D(
        35866,
        0,
        glInternalFormat,
        image.width,
        image.height,
        image.depth,
        0,
        glFormat,
        glType,
        image.data
      );
      textureProperties.__maxMipLevel = 0;
    } else {
      // regular Texture (image, video, canvas)

      // use manually created mipmaps if available
      // if there are no manual mipmaps
      // set 0 level mipmap and then use GL to generate other mipmap levels

      if (mipmaps.length > 0 && supportsMips) {
        for (let i = 0, il = mipmaps.length; i < il; i++) {
          mipmap = mipmaps[i];
          state.texImage2D(3553, i, glInternalFormat, glFormat, glType, mipmap);
        }

        texture.generateMipmaps = false;
        textureProperties.__maxMipLevel = mipmaps.length - 1;
      } else {
        state.texImage2D(3553, 0, glInternalFormat, glFormat, glType, image);
        textureProperties.__maxMipLevel = 0;
      }
    }

    if (textureNeedsGenerateMipmaps(texture, supportsMips)) {
      generateMipmap(textureType, texture, image.width, image.height);
    }

    textureProperties.__version = texture.version;

    if (texture.onUpdate) texture.onUpdate(texture);
  }

  // Render targets

  // Setup storage for target texture and bind it to correct framebuffer
  function setupFrameBufferTexture(
    framebuffer,
    renderTarget,
    attachment,
    textureTarget
  ) {
    const glFormat = utils.convert(renderTarget.texture.format);
    const glType = utils.convert(renderTarget.texture.type);
    const glInternalFormat = getInternalFormat(
      renderTarget.texture.internalFormat,
      glFormat,
      glType
    );
    state.texImage2D(
      textureTarget,
      0,
      glInternalFormat,
      renderTarget.width,
      renderTarget.height,
      0,
      glFormat,
      glType,
      null
    );
    _gl.bindFramebuffer(36160, framebuffer);
    _gl.framebufferTexture2D(
      36160,
      attachment,
      textureTarget,
      properties.get(renderTarget.texture).__webglTexture,
      0
    );
    _gl.bindFramebuffer(36160, null);
  }

  // Setup storage for internal depth/stencil buffers and bind to correct framebuffer
  function setupRenderBufferStorage(renderbuffer, renderTarget, isMultisample) {
    _gl.bindRenderbuffer(36161, renderbuffer);

    if (renderTarget.depthBuffer && !renderTarget.stencilBuffer) {
      let glInternalFormat = 33189;

      if (isMultisample) {
        const depthTexture = renderTarget.depthTexture;

        if (depthTexture && depthTexture.isDepthTexture) {
          if (depthTexture.type === FloatType) {
            glInternalFormat = 36012;
          } else if (depthTexture.type === UnsignedIntType) {
            glInternalFormat = 33190;
          }
        }

        const samples = getRenderTargetSamples(renderTarget);

        _gl.renderbufferStorageMultisample(
          36161,
          samples,
          glInternalFormat,
          renderTarget.width,
          renderTarget.height
        );
      } else {
        _gl.renderbufferStorage(
          36161,
          glInternalFormat,
          renderTarget.width,
          renderTarget.height
        );
      }

      _gl.framebufferRenderbuffer(36160, 36096, 36161, renderbuffer);
    } else if (renderTarget.depthBuffer && renderTarget.stencilBuffer) {
      if (isMultisample) {
        const samples = getRenderTargetSamples(renderTarget);

        _gl.renderbufferStorageMultisample(
          36161,
          samples,
          35056,
          renderTarget.width,
          renderTarget.height
        );
      } else {
        _gl.renderbufferStorage(
          36161,
          34041,
          renderTarget.width,
          renderTarget.height
        );
      }

      _gl.framebufferRenderbuffer(36160, 33306, 36161, renderbuffer);
    } else {
      const glFormat = utils.convert(renderTarget.texture.format);
      const glType = utils.convert(renderTarget.texture.type);
      const glInternalFormat = getInternalFormat(
        renderTarget.texture.internalFormat,
        glFormat,
        glType
      );

      if (isMultisample) {
        const samples = getRenderTargetSamples(renderTarget);

        _gl.renderbufferStorageMultisample(
          36161,
          samples,
          glInternalFormat,
          renderTarget.width,
          renderTarget.height
        );
      } else {
        _gl.renderbufferStorage(
          36161,
          glInternalFormat,
          renderTarget.width,
          renderTarget.height
        );
      }
    }

    _gl.bindRenderbuffer(36161, null);
  }

  // Setup resources for a Depth Texture for a FBO (needs an extension)
  function setupDepthTexture(framebuffer, renderTarget) {
    _gl.bindFramebuffer(36160, framebuffer);

    if (
      !(renderTarget.depthTexture && renderTarget.depthTexture.isDepthTexture)
    ) {
      throw new Error(
        "renderTarget.depthTexture must be an instance of THREE.DepthTexture"
      );
    }

    // upload an empty depth texture with framebuffer size
    if (
      !properties.get(renderTarget.depthTexture).__webglTexture ||
      renderTarget.depthTexture.image.width !== renderTarget.width ||
      renderTarget.depthTexture.image.height !== renderTarget.height
    ) {
      renderTarget.depthTexture.image.width = renderTarget.width;
      renderTarget.depthTexture.image.height = renderTarget.height;
      renderTarget.depthTexture.needsUpdate = true;
    }

    setTexture2D(renderTarget.depthTexture, 0);

    const webglDepthTexture = properties.get(
      renderTarget.depthTexture
    ).__webglTexture;

    if (renderTarget.depthTexture.format === DepthFormat) {
      _gl.framebufferTexture2D(36160, 36096, 3553, webglDepthTexture, 0);
    } else if (renderTarget.depthTexture.format === DepthStencilFormat) {
      _gl.framebufferTexture2D(36160, 33306, 3553, webglDepthTexture, 0);
    } else {
      throw new Error("Unknown depthTexture format");
    }
  }

  // Setup GL resources for a non-texture depth buffer
  function setupDepthRenderbuffer(renderTarget) {
    const renderTargetProperties = properties.get(renderTarget);

    if (renderTarget.depthTexture) {
      setupDepthTexture(
        renderTargetProperties.__webglFramebuffer,
        renderTarget
      );
    } else {
      _gl.bindFramebuffer(36160, renderTargetProperties.__webglFramebuffer);
      renderTargetProperties.__webglDepthbuffer = _gl.createRenderbuffer();
      setupRenderBufferStorage(
        renderTargetProperties.__webglDepthbuffer,
        renderTarget,
        false
      );
    }

    _gl.bindFramebuffer(36160, null);
  }

  // Set up GL resources for the render target
  function setupRenderTarget(renderTarget) {
    const renderTargetProperties = properties.get(renderTarget);
    const textureProperties = properties.get(renderTarget.texture);

    renderTarget.addEventListener("dispose", onRenderTargetDispose);

    textureProperties.__webglTexture = _gl.createTexture();

    info.memory.textures++;

    const isMultisample = renderTarget.isWebGLMultisampleRenderTarget === true;
    const supportsMips = true;

    // Handles WebGL2 RGBFormat fallback - #18858

    if (
      renderTarget.texture.format === RGBFormat &&
      (renderTarget.texture.type === FloatType ||
        renderTarget.texture.type === HalfFloatType)
    ) {
      renderTarget.texture.format = RGBAFormat;

      console.warn(
        "THREE.WebGLRenderer: Rendering to textures with RGB format is not supported. Using RGBA format instead."
      );
    }

    // Setup framebuffer
    renderTargetProperties.__webglFramebuffer = _gl.createFramebuffer();

    if (isMultisample) {
      renderTargetProperties.__webglMultisampledFramebuffer =
        _gl.createFramebuffer();
      renderTargetProperties.__webglColorRenderbuffer =
        _gl.createRenderbuffer();

      _gl.bindRenderbuffer(
        36161,
        renderTargetProperties.__webglColorRenderbuffer
      );

      const glFormat = utils.convert(renderTarget.texture.format);
      const glType = utils.convert(renderTarget.texture.type);
      const glInternalFormat = getInternalFormat(
        renderTarget.texture.internalFormat,
        glFormat,
        glType
      );
      const samples = getRenderTargetSamples(renderTarget);
      _gl.renderbufferStorageMultisample(
        36161,
        samples,
        glInternalFormat,
        renderTarget.width,
        renderTarget.height
      );

      _gl.bindFramebuffer(
        36160,
        renderTargetProperties.__webglMultisampledFramebuffer
      );
      _gl.framebufferRenderbuffer(
        36160,
        36064,
        36161,
        renderTargetProperties.__webglColorRenderbuffer
      );
      _gl.bindRenderbuffer(36161, null);

      if (renderTarget.depthBuffer) {
        renderTargetProperties.__webglDepthRenderbuffer =
          _gl.createRenderbuffer();
        setupRenderBufferStorage(
          renderTargetProperties.__webglDepthRenderbuffer,
          renderTarget,
          true
        );
      }

      _gl.bindFramebuffer(36160, null);
    }

    // Setup color buffer
    state.bindTexture(3553, textureProperties.__webglTexture);
    setTextureParameters(3553, renderTarget.texture, supportsMips);
    setupFrameBufferTexture(
      renderTargetProperties.__webglFramebuffer,
      renderTarget,
      36064,
      3553
    );

    if (textureNeedsGenerateMipmaps(renderTarget.texture, supportsMips)) {
      generateMipmap(
        3553,
        renderTarget.texture,
        renderTarget.width,
        renderTarget.height
      );
    }

    state.bindTexture(3553, null);

    // Setup depth and stencil buffers

    if (renderTarget.depthBuffer) {
      setupDepthRenderbuffer(renderTarget);
    }
  }

  function updateRenderTargetMipmap(renderTarget) {
    const texture = renderTarget.texture;
    const supportsMips = true;

    if (textureNeedsGenerateMipmaps(texture, supportsMips)) {
      const target = 3553;
      const webglTexture = properties.get(texture).__webglTexture;

      state.bindTexture(target, webglTexture);
      generateMipmap(target, texture, renderTarget.width, renderTarget.height);
      state.bindTexture(target, null);
    }
  }

  function updateMultisampleRenderTarget(renderTarget) {
    if (renderTarget.isWebGLMultisampleRenderTarget) {
      const renderTargetProperties = properties.get(renderTarget);

      _gl.bindFramebuffer(
        36008,
        renderTargetProperties.__webglMultisampledFramebuffer
      );
      _gl.bindFramebuffer(36009, renderTargetProperties.__webglFramebuffer);

      const width = renderTarget.width;
      const height = renderTarget.height;
      let mask = 16384;

      if (renderTarget.depthBuffer) mask |= 256;
      if (renderTarget.stencilBuffer) mask |= 1024;

      _gl.blitFramebuffer(0, 0, width, height, 0, 0, width, height, mask, 9728);

      _gl.bindFramebuffer(
        36160,
        renderTargetProperties.__webglMultisampledFramebuffer
      ); // see #18905
    }
  }

  function getRenderTargetSamples(renderTarget) {
    return renderTarget.isWebGLMultisampleRenderTarget
      ? Math.min(maxSamples, renderTarget.samples)
      : 0;
  }

  // backwards compatibility

  let warnedTexture2D = false;

  function safeSetTexture2D(texture, slot) {
    if (texture && texture.isWebGLRenderTarget) {
      if (warnedTexture2D === false) {
        console.warn(
          "THREE.WebGLTextures.safeSetTexture2D: don't use render targets as textures. Use their .texture property instead."
        );
        warnedTexture2D = true;
      }

      texture = texture.texture;
    }

    setTexture2D(texture, slot);
  }

  //

  this.allocateTextureUnit = allocateTextureUnit;
  this.resetTextureUnits = resetTextureUnits;

  this.setTexture2D = setTexture2D;
  this.setTexture2DArray = setTexture2DArray;
  this.setTexture3D = setTexture3D;
  this.setupRenderTarget = setupRenderTarget;
  this.updateRenderTargetMipmap = updateRenderTargetMipmap;
  this.updateMultisampleRenderTarget = updateMultisampleRenderTarget;

  this.safeSetTexture2D = safeSetTexture2D;
}

function WebGLUtils(extensions) {
  function convert(p) {
    // ARKION OLIVER: WHY IS THERE ANOTHER ONE? paramThreeToGL WASN'T ENOUGH????
    let extension;

    if (p === UnsignedByteType) return 5121;
    if (p === UnsignedShort4444Type) return 32819;
    if (p === UnsignedShort5551Type) return 32820;
    if (p === UnsignedShort565Type) return 33635;

    if (p === ByteType) return 5120;
    if (p === ShortType) return 5122;
    if (p === UnsignedShortType) return 5123;
    if (p === IntType) return 5124;
    if (p === UnsignedIntType) return 5125;
    if (p === FloatType) return 5126;

    if (p === HalfFloatType) {
      return 5131;
    }

    if (p === AlphaFormat) return 6406;
    if (p === RGBFormat) return 6407;
    if (p === RGBAFormat) return 6408;
    if (p === LuminanceFormat) return 6409;
    if (p === LuminanceAlphaFormat) return 6410;
    if (p === DepthFormat) return 6402;
    if (p === DepthStencilFormat) return 34041;
    if (p === RedFormat) return 6403;

    // WebGL2 formats.

    if (p === RedIntegerFormat) return 36244;
    if (p === RGFormat) return 33319;
    if (p === RGIntegerFormat) return 33320;
    if (p === RGBIntegerFormat) return 36248;
    if (p === RGBAIntegerFormat) return 36249;

    if (
      p === RGB_S3TC_DXT1_Format ||
      p === RGBA_S3TC_DXT1_Format ||
      p === RGBA_S3TC_DXT3_Format ||
      p === RGBA_S3TC_DXT5_Format
    ) {
      extension = extensions.get("WEBGL_compressed_texture_s3tc");

      if (extension !== null) {
        if (p === RGB_S3TC_DXT1_Format)
          return extension.COMPRESSED_RGB_S3TC_DXT1_EXT;
        if (p === RGBA_S3TC_DXT1_Format)
          return extension.COMPRESSED_RGBA_S3TC_DXT1_EXT;
        if (p === RGBA_S3TC_DXT3_Format)
          return extension.COMPRESSED_RGBA_S3TC_DXT3_EXT;
        if (p === RGBA_S3TC_DXT5_Format)
          return extension.COMPRESSED_RGBA_S3TC_DXT5_EXT;
      } else {
        return null;
      }
    }

    if (
      p === RGB_PVRTC_4BPPV1_Format ||
      p === RGB_PVRTC_2BPPV1_Format ||
      p === RGBA_PVRTC_4BPPV1_Format ||
      p === RGBA_PVRTC_2BPPV1_Format
    ) {
      extension = extensions.get("WEBGL_compressed_texture_pvrtc");

      if (extension !== null) {
        if (p === RGB_PVRTC_4BPPV1_Format)
          return extension.COMPRESSED_RGB_PVRTC_4BPPV1_IMG;
        if (p === RGB_PVRTC_2BPPV1_Format)
          return extension.COMPRESSED_RGB_PVRTC_2BPPV1_IMG;
        if (p === RGBA_PVRTC_4BPPV1_Format)
          return extension.COMPRESSED_RGBA_PVRTC_4BPPV1_IMG;
        if (p === RGBA_PVRTC_2BPPV1_Format)
          return extension.COMPRESSED_RGBA_PVRTC_2BPPV1_IMG;
      } else {
        return null;
      }
    }

    if (p === RGB_ETC1_Format) {
      extension = extensions.get("WEBGL_compressed_texture_etc1");

      if (extension !== null) {
        return extension.COMPRESSED_RGB_ETC1_WEBGL;
      } else {
        return null;
      }
    }

    if (p === RGB_ETC2_Format || p === RGBA_ETC2_EAC_Format) {
      extension = extensions.get("WEBGL_compressed_texture_etc");

      if (extension !== null) {
        if (p === RGB_ETC2_Format) return extension.COMPRESSED_RGB8_ETC2;
        if (p === RGBA_ETC2_EAC_Format)
          return extension.COMPRESSED_RGBA8_ETC2_EAC;
      }
    }

    if (
      p === RGBA_ASTC_4x4_Format ||
      p === RGBA_ASTC_5x4_Format ||
      p === RGBA_ASTC_5x5_Format ||
      p === RGBA_ASTC_6x5_Format ||
      p === RGBA_ASTC_6x6_Format ||
      p === RGBA_ASTC_8x5_Format ||
      p === RGBA_ASTC_8x6_Format ||
      p === RGBA_ASTC_8x8_Format ||
      p === RGBA_ASTC_10x5_Format ||
      p === RGBA_ASTC_10x6_Format ||
      p === RGBA_ASTC_10x8_Format ||
      p === RGBA_ASTC_10x10_Format ||
      p === RGBA_ASTC_12x10_Format ||
      p === RGBA_ASTC_12x12_Format ||
      p === SRGB8_ALPHA8_ASTC_4x4_Format ||
      p === SRGB8_ALPHA8_ASTC_5x4_Format ||
      p === SRGB8_ALPHA8_ASTC_5x5_Format ||
      p === SRGB8_ALPHA8_ASTC_6x5_Format ||
      p === SRGB8_ALPHA8_ASTC_6x6_Format ||
      p === SRGB8_ALPHA8_ASTC_8x5_Format ||
      p === SRGB8_ALPHA8_ASTC_8x6_Format ||
      p === SRGB8_ALPHA8_ASTC_8x8_Format ||
      p === SRGB8_ALPHA8_ASTC_10x5_Format ||
      p === SRGB8_ALPHA8_ASTC_10x6_Format ||
      p === SRGB8_ALPHA8_ASTC_10x8_Format ||
      p === SRGB8_ALPHA8_ASTC_10x10_Format ||
      p === SRGB8_ALPHA8_ASTC_12x10_Format ||
      p === SRGB8_ALPHA8_ASTC_12x12_Format
    ) {
      extension = extensions.get("WEBGL_compressed_texture_astc");

      if (extension !== null) {
        return p;
      } else {
        return null;
      }
    }

    if (p === RGBA_BPTC_Format) {
      extension = extensions.get("EXT_texture_compression_bptc");

      if (extension !== null) {
        return p;
      } else {
        return null;
      }
    }

    if (p === UnsignedInt248Type) {
      return 34042;
    }
  }

  return { convert: convert };
}

function createCanvasElement() {
  const canvas = document.createElementNS(
    "http://www.w3.org/1999/xhtml",
    "canvas"
  );
  canvas.style.display = "block";
  return canvas;
}

export class WebGLRenderer {
  #canvas = null;
  #context = null;
  #alpha = false;
  #depth = true;
  #stencil = true;
  #antialias = false;
  #premultipliedAlpha = true;
  #preserveDrawingBuffer = false;
  #powerPreference = "default";
  #failIfMajorPerformanceCaveat = false;

  #pixelRatio = 1;
  #width = 0;
  #height = 0;

  #viewport = new Vector4();
  #currentViewport = new Vector4();

  #scissor = new Vector4();
  #currentScissor = new Vector4();
  #scissorTest = false;
  #currentScissorTest;

  #currentRenderTarget = null;

  #textures;

  constructor(parameters) {
    parameters = parameters || {};

    this.#canvas = parameters.canvas ?? createCanvasElement();
    this.#context = parameters.context ?? null;
    this.#alpha = parameters.alpha ?? false;
    this.#depth = parameters.depth ?? true;
    this.#stencil = parameters.stencil ?? true;
    this.#antialias = parameters.antialias ?? false;
    this.#premultipliedAlpha = parameters.premultipliedAlpha ?? true;
    this.#preserveDrawingBuffer = parameters.preserveDrawingBuffer ?? false;
    this.#powerPreference = parameters.powerPreference ?? "default";
    this.#failIfMajorPerformanceCaveat =
      parameters.failIfMajorPerformanceCaveat ?? false;

    // render() can be called from within a callback triggered by another render.
    // We track this so that the nested render call gets its state isolated from the parent render call.

    // public properties
    this.domElement = this.#canvas;

    // internal state cache
    let _framebuffer = null;

    let _currentFramebuffer = null;

    //
    this.#width = this.#canvas.width;
    this.#height = this.#canvas.height;

    this.#viewport = new Vector4(0, 0, this.#width, this.#height);
    this.#scissor = new Vector4(0, 0, this.#width, this.#height);

    // initialize
    let _gl = this.#context;

    function getContext(contextNames, contextAttributes) {
      for (let i = 0; i < contextNames.length; i++) {
        const contextName = contextNames[i];
        const context = this.#canvas.getContext(contextName, contextAttributes);
        if (context !== null) return context;
      }

      return null;
    }

    try {
      const contextAttributes = {
        alpha: this.#alpha,
        depth: this.#depth,
        stencil: this.#stencil,
        antialias: this.#antialias,
        premultipliedAlpha: this.#premultipliedAlpha,
        preserveDrawingBuffer: this.#preserveDrawingBuffer,
        powerPreference: this.#powerPreference,
        failIfMajorPerformanceCaveat: this.#failIfMajorPerformanceCaveat,
      };

      // event listeners must be registered before WebGL context is created, see #12753
      this.#canvas.addEventListener(
        "webglcontextlost",
        this.onContextLost.bind(this),
        false
      );
      this.#canvas.addEventListener(
        "webglcontextrestored",
        this.onContextRestore.bind(this),
        false
      );

      if (_gl === null) {
        const contextNames = ["webgl2", "experimental-webgl"];

        _gl = getContext(contextNames, contextAttributes);

        if (_gl === null) {
          if (getContext(contextNames)) {
            throw new Error(
              "Error creating WebGL context with your selected attributes."
            );
          } else {
            throw new Error("Error creating WebGL context.");
          }
        }
      }

      // Some experimental-webgl implementations do not have getShaderPrecisionFormat
      if (_gl.getShaderPrecisionFormat === undefined) {
        _gl.getShaderPrecisionFormat = function () {
          return { rangeMin: 1, rangeMax: 1, precision: 1 };
        };
      }
    } catch (error) {
      console.error("THREE.WebGLRenderer: " + error.message);
      throw error;
    }

    let extensions, capabilities, state, info;
    let properties;
    let renderStates;

    let utils, bindingStates;

    extensions = new WebGLExtensions(_gl);

    capabilities = new WebGLCapabilities(_gl, extensions, parameters);

    extensions.get("OES_texture_float_linear");

    utils = new WebGLUtils(extensions);

    state = new WebGLState(_gl);
    state.scissor(
      this.#currentScissor
        .copy(this.#scissor)
        .multiplyScalar(this.#pixelRatio)
        .floor()
    );
    state.viewport(
      this.#currentViewport
        .copy(this.#viewport)
        .multiplyScalar(this.#pixelRatio)
        .floor()
    );

    info = new WebGLInfo(_gl);
    properties = new WebGLProperties();
    this.#textures = new WebGLTextures(
      _gl,
      extensions,
      state,
      properties,
      capabilities,
      utils,
      info
    );
    this.attributes = new WebGLAttributes(_gl);
    bindingStates = new WebGLBindingStates(_gl, this.attributes);
    renderStates = new WebGLRenderStates();
    this.bindingStates = bindingStates;
    this.capabilities = capabilities;
    this.extensions = extensions;
    this.properties = properties;
    this.renderStates = renderStates;
    this.state = state;
    this.info = info;

    // Animation Loop
    let onAnimationFrameCallback = null;

    function onAnimationFrame(time) {
      if (onAnimationFrameCallback) onAnimationFrameCallback(time);
    }

    const animation = new WebGLAnimation();
    animation.setAnimationLoop(onAnimationFrame);

    if (typeof window !== "undefined") animation.setContext(window);

    this.setAnimationLoop = function (callback) {
      onAnimationFrameCallback = callback;

      callback === null ? animation.stop() : animation.start();
    };

    this.animation = animation;

    this.setRenderTarget = function (renderTarget) {
      this.#currentRenderTarget = renderTarget;

      if (
        renderTarget &&
        properties.get(renderTarget).__webglFramebuffer === undefined
      ) {
        this.#textures.setupRenderTarget(renderTarget);
      }

      let framebuffer = _framebuffer;
      if (renderTarget) {
        const __webglFramebuffer =
          properties.get(renderTarget).__webglFramebuffer;

        if (renderTarget.isWebGLMultisampleRenderTarget) {
          framebuffer =
            properties.get(renderTarget).__webglMultisampledFramebuffer;
        } else {
          framebuffer = __webglFramebuffer;
        }

        this.#currentViewport.copy(renderTarget.viewport);
        this.#currentScissor.copy(renderTarget.scissor);
        this.#currentScissorTest = renderTarget.scissorTest;
      } else {
        this.#currentViewport
          .copy(this.#viewport)
          .multiplyScalar(this.#pixelRatio)
          .floor();
        this.#currentScissor
          .copy(this.#scissor)
          .multiplyScalar(this.#pixelRatio)
          .floor();
        this.#currentScissorTest = this.#scissorTest;
      }

      if (_currentFramebuffer !== framebuffer) {
        _gl.bindFramebuffer(36160, framebuffer);
        _currentFramebuffer = framebuffer;
      }

      state.viewport(this.#currentViewport);
      state.scissor(this.#currentScissor);
      state.setScissorTest(this.#currentScissorTest);
    };

    this.initTexture = function (texture) {
      this.#textures.setTexture2D(texture, 0);

      state.unbindTexture();
    };

    this.resetState = function () {
      state.reset();
      bindingStates.reset();
    };
  }

  getTargetPixelRatio() {
    return this.#currentRenderTarget === null ? this.#pixelRatio : 1;
  }

  getContext() {
    return this.#context;
  }

  getContextAttributes() {
    return this.#context.getContextAttributes();
  }

  getPixelRatio() {
    return this.#pixelRatio;
  }
  setPixelRatio(value) {
    if (value === undefined) return;

    this.#pixelRatio = value;

    this.setSize(this.#width, this.#height, false);
  }

  getSize(target) {
    return target.set(this.#width, this.#height);
  }
  setSize(width, height, updateStyle) {
    this.#width = width;
    this.#height = height;

    this.#canvas.width = Math.floor(width * this.#pixelRatio);
    this.#canvas.height = Math.floor(height * this.#pixelRatio);

    if (updateStyle !== false) {
      this.#canvas.style.width = width + "px";
      this.#canvas.style.height = height + "px";
    }

    this.setViewport(0, 0, width, height);
  }

  getCurrentViewport(target) {
    return target.copy(this.#currentViewport);
  }

  getViewport(target) {
    return target.copy(this.#viewport);
  }

  setViewport(x, y, width, height) {
    if (x.isVector4) {
      this.#viewport.set(x.x, x.y, x.z, x.w);
    } else {
      this.#viewport.set(x, y, width, height);
    }

    this.state.viewport(
      this.#currentViewport
        .copy(this.#viewport)
        .multiplyScalar(this.#pixelRatio)
        .floor()
    );
  }

  setScissorTest(boolean) {
    this.state.setScissorTest(this.#scissorTest === boolean);
  }

  clear(color, depth, stencil) {
    let bits = 0;

    if (color === undefined || color) bits |= 16384;
    if (depth === undefined || depth) bits |= 256;
    if (stencil === undefined || stencil) bits |= 1024;

    this.#context.clear(bits);
  }

  //
  dispose() {
    this.#canvas.removeEventListener(
      "webglcontextlost",
      this.onContextLost,
      false
    );
    this.#canvas.removeEventListener(
      "webglcontextrestored",
      this.onContextRestore,
      false
    );

    this.renderStates.dispose();
    this.properties.dispose();
    this.bindingStates.dispose();

    this.animation.stop();
  }

  // Events
  onContextLost(event) {
    event.preventDefault();

    console.log("THREE.WebGLRenderer: Context Lost.");
  }

  onContextRestore(/* event */) {
    console.log("THREE.WebGLRenderer: Context Restored.");

    //initGLContext();
  }
}

export const ShaderFeatures = (function () {
  let ftCanvas = document.createElement("canvas");
  let gl = ftCanvas.getContext("webgl2");
  if (gl === null) {
    return null;
  }

  // -- code taken from THREE.WebGLRenderer --
  let _vertexShaderPrecisionHighpFloat = gl.getShaderPrecisionFormat(
    gl.VERTEX_SHADER,
    gl.HIGH_FLOAT
  );
  let _vertexShaderPrecisionMediumpFloat = gl.getShaderPrecisionFormat(
    gl.VERTEX_SHADER,
    gl.MEDIUM_FLOAT
  );

  let _fragmentShaderPrecisionHighpFloat = gl.getShaderPrecisionFormat(
    gl.FRAGMENT_SHADER,
    gl.HIGH_FLOAT
  );
  let _fragmentShaderPrecisionMediumpFloat = gl.getShaderPrecisionFormat(
    gl.FRAGMENT_SHADER,
    gl.MEDIUM_FLOAT
  );

  let highpAvailable =
    _vertexShaderPrecisionHighpFloat.precision > 0 &&
    _fragmentShaderPrecisionHighpFloat.precision > 0;
  let mediumpAvailable =
    _vertexShaderPrecisionMediumpFloat.precision > 0 &&
    _fragmentShaderPrecisionMediumpFloat.precision > 0;
  // -----------------------------------------

  let precision;
  if (highpAvailable) {
    precision = "highp";
  } else if (mediumpAvailable) {
    precision = "mediump";
  } else {
    precision = "lowp";
  }

  const edl_supported = gl.getParameter(gl.MAX_VARYING_VECTORS) >= 8;

  return {
    SHADER_EDL: edl_supported,
    precision: precision,
  };
})();

export class WebGLTexture {
  constructor(gl, texture) {
    this.gl = gl;

    this.texture = texture;
    this.id = gl.createTexture();

    this.target = gl.TEXTURE_2D;
    this.version = -1;

    this.update();
  }

  update() {
    if (!this.texture.image) {
      this.version = this.texture.version;

      return;
    }

    let gl = this.gl;
    let texture = this.texture;

    if (!texture.needsUpdate) {
      return;
    }
    texture.needsUpdate = false;

    this.target = gl.TEXTURE_2D;

    gl.bindTexture(this.target, this.id);

    let level = 0;
    let internalFormat = paramThreeToGL(gl, texture.format);
    let width = texture.image.width;
    let height = texture.image.height;
    let border = 0;
    let srcFormat = internalFormat;
    let srcType = paramThreeToGL(gl, texture.type);
    let data;

    gl.pixelStorei(gl.UNPACK_FLIP_Y_WEBGL, texture.flipY);
    gl.pixelStorei(gl.UNPACK_PREMULTIPLY_ALPHA_WEBGL, texture.premultiplyAlpha);
    gl.pixelStorei(gl.UNPACK_ALIGNMENT, texture.unpackAlignment);

    if (texture instanceof DataTexture) {
      data = texture.image.data;

      gl.texParameteri(this.target, gl.TEXTURE_WRAP_S, gl.CLAMP_TO_EDGE);
      gl.texParameteri(this.target, gl.TEXTURE_WRAP_T, gl.CLAMP_TO_EDGE);

      gl.texParameteri(
        this.target,
        gl.TEXTURE_MAG_FILTER,
        paramThreeToGL(gl, texture.magFilter)
      );
      gl.texParameteri(
        this.target,
        gl.TEXTURE_MIN_FILTER,
        paramThreeToGL(gl, texture.minFilter)
      );

      gl.texImage2D(
        this.target,
        level,
        internalFormat,
        width,
        height,
        border,
        srcFormat,
        srcType,
        data
      );
    } else if (texture instanceof CanvasTexture || texture instanceof Texture) {
      data = texture.image;

      gl.texParameteri(
        this.target,
        gl.TEXTURE_WRAP_S,
        paramThreeToGL(gl, texture.wrapS)
      );
      gl.texParameteri(
        this.target,
        gl.TEXTURE_WRAP_T,
        paramThreeToGL(gl, texture.wrapT)
      );

      gl.texParameteri(
        this.target,
        gl.TEXTURE_MAG_FILTER,
        paramThreeToGL(gl, texture.magFilter)
      );
      gl.texParameteri(
        this.target,
        gl.TEXTURE_MIN_FILTER,
        paramThreeToGL(gl, texture.minFilter)
      );

      gl.texImage2D(
        this.target,
        level,
        internalFormat,
        internalFormat,
        srcType,
        data
      );

      if (texture instanceof Texture) {
        gl.generateMipmap(gl.TEXTURE_2D);
      }
    }

    gl.bindTexture(this.target, null);

    this.version = texture.version;
  }
}

export class WebGLBuffer {
  constructor() {
    this.numElements = 0;
    this.vao = null;
    this.vbos = new Map();
  }
}

// Copied from three.js: WebGLRenderer.js
function paramThreeToGL(_gl, p) {
  switch (p) {
    // Wrapping
    case RepeatWrapping:
      return _gl.REPEAT;
    case ClampToEdgeWrapping:
      return _gl.CLAMP_TO_EDGE;
    case MirroredRepeatWrapping:
      return _gl.MIRRORED_REPEAT;

    // Nearest Filters
    case NearestFilter:
      return _gl.NEAREST;
    case NearestMipMapNearestFilter:
      return _gl.NEAREST_MIPMAP_NEAREST;
    case NearestMipMapLinearFilter:
      return _gl.NEAREST_MIPMAP_LINEAR;
    // Linear Filters
    case LinearFilter:
      return _gl.LINEAR;
    case LinearMipMapNearestFilter:
      return _gl.LINEAR_MIPMAP_NEAREST;
    case LinearMipMapLinearFilter:
      return _gl.LINEAR_MIPMAP_LINEAR;

    // Unsinged Types
    case UnsignedByteType:
      return _gl.UNSIGNED_BYTE;
    case UnsignedShort4444Type:
      return _gl.UNSIGNED_SHORT_4_4_4_4;
    case UnsignedShort5551Type:
      return _gl.UNSIGNED_SHORT_5_5_5_1;
    case UnsignedShort565Type:
      return _gl.UNSIGNED_SHORT_5_6_5;

    // Types
    case ByteType:
      return _gl.BYTE;
    case ShortType:
      return _gl.SHORT;
    case UnsignedShortType:
      return _gl.UNSIGNED_SHORT;
    case IntType:
      return _gl.INT;
    case UnsignedIntType:
      return _gl.UNSIGNED_INT;
    case FloatType:
      return _gl.FLOAT;

    // Formats
    case AlphaFormat:
      return _gl.ALPHA;
    case RGBFormat:
      return _gl.RGB;
    case RGBAFormat:
      return _gl.RGBA;
    case LuminanceFormat:
      return _gl.LUMINANCE;
    case LuminanceAlphaFormat:
      return _gl.LUMINANCE_ALPHA;
    case DepthFormat:
      return _gl.DEPTH_COMPONENT;
    case DepthStencilFormat:
      return _gl.DEPTH_STENCIL;

    // Equations
    case AddEquation:
      return _gl.FUNC_ADD;
    case SubtractEquation:
      return _gl.FUNC_SUBTRACT;
    case ReverseSubtractEquation:
      return _gl.FUNC_REVERSE_SUBTRACT;

    // Factors
    case ZeroFactor:
      return _gl.ZERO;
    case OneFactor:
      return _gl.ONE;
    case SrcColorFactor:
      return _gl.SRC_COLOR;
    case OneMinusSrcColorFactor:
      return _gl.ONE_MINUS_SRC_COLOR;
    case SrcAlphaFactor:
      return _gl.SRC_ALPHA;
    case OneMinusSrcAlphaFactor:
      return _gl.ONE_MINUS_SRC_ALPHA;
    case DstAlphaFactor:
      return _gl.DST_ALPHA;
    case OneMinusDstAlphaFactor:
      return _gl.ONE_MINUS_DST_ALPHA;

    case DstColorFactor:
      return _gl.DST_COLOR;
    case OneMinusDstColorFactor:
      return _gl.ONE_MINUS_DST_COLOR;
    case SrcAlphaSaturateFactor:
      return _gl.SRC_ALPHA_SATURATE;

    default:
      return 0;
  }
}
