import { useEffect } from "react";
import { Paper, Stack, Typography } from "@mui/material";
import { Loading } from "react/views";
import { useNavigate, useLocation } from "react-router-dom";
import axios from "axios";
import { BASE_URI } from "config";

interface IProps {
  setLoggedIn: (loggedIn: boolean) => void;
}
export default function CheckingCredentials({ setLoggedIn }: IProps) {
  const navigate = useNavigate();
  const location = useLocation();

  useEffect(() => {
    const currentLink = encodeURIComponent(location.pathname + location.search);

    axios
      .get(`${BASE_URI}/auth`, { withCredentials: true })
      .then(({ data }) => {
        if (data?.loggedIn) {
          setLoggedIn(true);
        } else {
          setLoggedIn(false);
          navigate(`/?redirect=${currentLink}`);
        }
      })
      .catch(() => {
        setLoggedIn(false);
        navigate(`/?redirect=${currentLink}`);
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps -- Only run on mount
  }, []);

  return (
    <Stack justifyContent="center" alignItems="center" flex={1}>
      <Paper sx={{ p: 2 }}>
        <Stack spacing={2} pt={2}>
          <Loading size={40} relative />
          <Typography>Checking Credentials</Typography>
        </Stack>
      </Paper>
    </Stack>
  );
}
