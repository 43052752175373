import { SQSphereBuffer } from "potree/nova_renderer/buffer";
import { MOUSE } from "../constants";
import { Mesh } from "../geometry";
import { Vector3, Plane } from "../mathtypes";
import { MeshNormalMaterial } from "../rendering/material.js";
import * as Utils from "../utils/utils";
import BaseControls from "./BaseControls";
import { Raycaster } from "potree/raycasting";

export default class EarthControls extends BaseControls {
  #wheelDelta = 0;
  #zoomDelta = new Vector3();
  #camStart = null;

  #pivot = null;
  #pivotIndicator = null;
  #lastPivot = null;

  zoomMultiplier = 1.0;

  constructor(viewer) {
    super(viewer);

    {
      const sg = new SQSphereBuffer(1, 16, 16);
      const sm = new MeshNormalMaterial();
      // @ts-ignore (Mesh it not typescript friendly yet)
      this.#pivotIndicator = new Mesh(sg, sm);
      this.#pivotIndicator.visible = false;
      this.scene.add(this.#pivotIndicator);
    }

    const onMouseDown = (e) => {
      const I = Utils.getMousePointCloudIntersection(
        e.mouse,
        this.sceneContext.getActiveCamera(),
        this.viewer,
        this.sceneContext.pointclouds,
        { pickClipped: false }
      );

      if (I) {
        // Slightly hacky point debug tool.
        if (this.viewer.debugTools) {
          console.info(I.point);
        }
        this.#pivot = I.location;
      } else {
        this.#pivot = new Vector3();
        this.resolveFallbackPoint(e.mouse, this.#pivot);
      }

      this.#camStart = this.sceneContext.getActiveCamera().clone();
      this.#pivotIndicator.visible = true;
      this.#pivotIndicator.position.copy(this.#pivot);
    };

    const onMouseUp = (e) => {
      this.#camStart = null;
      this.#lastPivot = this.#pivot;
      this.#pivot = null;
      this.#pivotIndicator.visible = false;
    };

    this.addEventListener("mousedown", onMouseDown);
    this.addEventListener("mouseup", onMouseUp);
  }

  resolveFallbackPoint(mouse, target) {
    const renderer = this.viewer.renderContext;

    const nmouse = {
      x: (mouse.x / renderer.canvas.clientWidth) * 2 - 1,
      y: -(mouse.y / renderer.canvas.clientHeight) * 2 + 1,
    };

    const raycaster = new Raycaster();
    this.sceneContext.getActiveCamera().setRaycaster(raycaster, nmouse);
    const ray = raycaster.ray;

    const plane = new Plane().setFromNormalAndCoplanarPoint(
      new Vector3(0, 0, 1),
      new Vector3(
        0,
        0,
        this.#lastPivot !== null
          ? this.#lastPivot.z
          : this.viewer.sceneData.properties.offset_z
      )
    );

    ray.intersectPlane(plane, target);
  }

  onDrag(e) {
    if (e.drag.object !== null || !this.#pivot) {
      return;
    }

    if (e.drag.startHandled === undefined) {
      e.drag.startHandled = true;

      this.dispatchEvent({ type: "start" });
    }

    const camStart = this.#camStart;
    const camera = this.sceneContext.getActiveCamera();
    const view = this.viewer.sceneContext.view;

    const mouse = e.drag.end;
    const domElement = this.viewer.renderContext.canvas;

    if (e.drag.mouse === MOUSE.LEFT) {
      const ray = Utils.mouseToRay(
        mouse,
        camera,
        domElement.clientWidth,
        domElement.clientHeight
      );
      const plane = new Plane().setFromNormalAndCoplanarPoint(
        new Vector3(0, 0, 1),
        this.#pivot
      );

      const distanceToPlane = ray.distanceToPlane(plane);

      if (distanceToPlane > 0) {
        const I = new Vector3().addVectors(
          camStart.position,
          ray.direction.clone().multiplyScalar(distanceToPlane)
        );

        const movedBy = new Vector3().subVectors(I, this.#pivot);
        const newCamPos = camStart.position.clone().sub(movedBy);

        view.position.copy(newCamPos);

        {
          const distance = newCamPos.distanceTo(this.#pivot);
          const speed = view.radius / 2.5;

          view.radius = distance;
          this.viewer.setMoveSpeed(speed);
        }
      }
    } else if (e.drag.mouse === MOUSE.RIGHT) {
      const ndrag = {
        x: e.drag.lastDrag.x / domElement.clientWidth,
        y: e.drag.lastDrag.y / domElement.clientHeight,
      };

      const yawDelta = -ndrag.x * this.rotationSpeed * 0.5;
      const pitchDelta = -ndrag.y * this.rotationSpeed * 0.2;

      const pivotToCam = new Vector3().subVectors(view.position, this.#pivot);
      const side = view.getSide();

      pivotToCam.applyAxisAngle(side, pitchDelta);
      pivotToCam.applyAxisAngle(new Vector3(0, 0, 1), yawDelta);

      const newCam = new Vector3().addVectors(this.#pivot, pivotToCam);

      view.position.copy(newCam);
      view.yaw += yawDelta;
      view.pitch += pitchDelta;
    }
    view.dirty();
  }

  onScroll(e) {
    this.#wheelDelta += e.delta * this.zoomMultiplier;
  }

  stop() {
    this.#wheelDelta = 0;
    this.#zoomDelta.set(0, 0, 0);
  }

  update(delta: number) {
    const view = this.sceneContext.view;
    const fade = 0.5 ** (this.fadeFactor * delta);
    const progression = 1 - fade;
    const camera = this.sceneContext.getActiveCamera();
    const domElement = this.viewer.renderContext.canvas;

    // compute zoom
    if (this.#wheelDelta !== 0) {
      const I = Utils.getMousePointCloudIntersection(
        this.viewer.inputHandler.mouse,
        this.sceneContext.getActiveCamera(),
        this.viewer,
        this.sceneContext.pointclouds
      );

      const resolvedPos = new Vector3().addVectors(
        view.position,
        this.#zoomDelta
      );

      let location = null;
      if (I) {
        location = I.location;
      } else {
        const pivot_location = new Vector3();
        this.resolveFallbackPoint(
          this.viewer.inputHandler.mouse,
          pivot_location
        );

        location = pivot_location;
      }

      const distance = location.distanceTo(resolvedPos);
      let jumpDistance = this.#wheelDelta;
      if (distance > 10) {
        jumpDistance *= distance * 0.2;
      } else {
        jumpDistance *= 0.5;
        if (jumpDistance > distance) {
          jumpDistance = distance * 0.5;
        }
      }
      const targetDir = new Vector3().subVectors(location, view.position);
      targetDir.normalize();

      resolvedPos.add(targetDir.multiplyScalar(jumpDistance));
      this.#zoomDelta.subVectors(resolvedPos, view.position);

      {
        const distance = resolvedPos.distanceTo(location);
        view.radius = distance;
        const speed = view.radius / 2.5;
        this.viewer.setMoveSpeed(speed);
      }
    }

    // apply zoom
    if (this.#zoomDelta.length() !== 0) {
      const p = this.#zoomDelta.clone().multiplyScalar(progression);

      const newPos = new Vector3().addVectors(view.position, p);
      view.position.copy(newPos);
      view.dirty();
    }

    if (this.#pivotIndicator.visible) {
      const distance = this.#pivotIndicator.position.distanceTo(view.position);
      const pixelwidth = domElement.clientwidth;
      const pixelHeight = domElement.clientHeight;
      const pr = Utils.projectedRadius(
        1,
        camera,
        distance,
        pixelwidth,
        pixelHeight
      );
      const scale = 10 / pr;
      this.#pivotIndicator.scale.set(scale, scale, scale);
    }

    // decelerate over time
    this.#zoomDelta.multiplyScalar(fade);
    this.#wheelDelta = 0;

    this.#pivotIndicator.updateMatrixWorld();
  }
}
