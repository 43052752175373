import { EventDispatcher } from "potree/events";
import {
  Box3,
  Matrix4,
  Sphere,
  Vector3,
  Vector4,
  Plane,
} from "potree/mathtypes";
import { Ray } from "potree/raycasting";
import {
  canLoadMore,
  getPointBudget,
  lru,
  maxNodesLoading,
  startedLoading,
  stoppedLoading,
} from "./loading";
import {
  LinearFilter,
  NearestFilter,
  NoBlending,
  RGBAFormat,
} from "potree/rendering/constants";
import { LineSegments, Object3D } from "potree/object3d";
import { Frustum } from "potree/geometry";
import { LineBasicMaterial, PointsMaterial } from "potree/rendering/material";
import {
  ClipMethod,
  ClipTask,
  PointShape,
  PointSizeType,
} from "potree/constants";
import BinaryHeap from "BinaryHeap/BinaryHeap";
import { PointCloudMaterial } from "./material";
import { WebGLRenderTarget } from "webgl/renderTarget";
import { BufferAttribute } from "potree/rendering/bufferattribute";
import * as Utils from "../utils/utils";
import { BufferGeometry } from "potree/rendering/buffers";

const _inverseMatrix$2 = new Matrix4();
const _ray$2 = new Ray();
const _sphere$3 = new Sphere();
const _position$1 = new Vector3();

/**
 *
 * code adapted from three.js BoxHelper.js
 * https://github.com/mrdoob/three.js/blob/dev/src/helpers/BoxHelper.js
 *
 * @author mrdoob / http://mrdoob.com/
 * @author Mugen87 / http://github.com/Mugen87
 * @author mschuetz / http://potree.org
 */

class Box3Helper$1 extends LineSegments {
  constructor(box, color) {
    const indices = new Uint16Array([
      0, 1, 1, 2, 2, 3, 3, 0, 4, 5, 5, 6, 6, 7, 7, 4, 0, 4, 1, 5, 2, 6, 3, 7,
    ]);
    const positions = new Float32Array([
      box.min.x,
      box.min.y,
      box.min.z,
      box.max.x,
      box.min.y,
      box.min.z,
      box.max.x,
      box.min.y,
      box.max.z,
      box.min.x,
      box.min.y,
      box.max.z,
      box.min.x,
      box.max.y,
      box.min.z,
      box.max.x,
      box.max.y,
      box.min.z,
      box.max.x,
      box.max.y,
      box.max.z,
      box.min.x,
      box.max.y,
      box.max.z,
    ]);

    let geometry = new BufferGeometry();
    geometry.setIndex(new BufferAttribute(indices, 1));
    geometry.setAttribute("position", new BufferAttribute(positions, 3));

    const material = new LineBasicMaterial({ color: color ?? 0xffff00 });

    super(geometry, material);
  }
}

class Points extends Object3D {
  isPoints = true;

  constructor(
    geometry = new BufferGeometry(),
    material = new PointsMaterial()
  ) {
    super();

    this.type = "Points";

    this.geometry = geometry;
    this.material = material;
  }

  static testPoint(
    point,
    index,
    localThresholdSq,
    matrixWorld,
    raycaster,
    intersects,
    object
  ) {
    const rayPointDistanceSq = _ray$2.distanceSqToPoint(point);

    if (rayPointDistanceSq < localThresholdSq) {
      const intersectPoint = new Vector3();

      _ray$2.closestPointToPoint(point, intersectPoint);
      intersectPoint.applyMatrix4(matrixWorld);

      const distance = raycaster.ray.origin.distanceTo(intersectPoint);

      if (distance < raycaster.near || distance > raycaster.far) return;

      intersects.push({
        distance: distance,
        distanceToRay: Math.sqrt(rayPointDistanceSq),
        point: intersectPoint,
        index: index,
        face: null,
        object: object,
      });
    }
  }

  copy(source) {
    Object3D.prototype.copy.call(this, source);

    this.material = source.material;
    this.geometry = source.geometry;

    return this;
  }

  raycast(raycaster, intersects) {
    const geometry = this.geometry;
    const matrixWorld = this.matrixWorld;
    const threshold = raycaster.params.Points.threshold;

    // Checking boundingSphere distance to ray

    if (geometry.boundingSphere === null) geometry.computeBoundingSphere();

    _sphere$3.copy(geometry.boundingSphere);
    _sphere$3.applyMatrix4(matrixWorld);
    _sphere$3.radius += threshold;

    if (raycaster.ray.intersectsSphere(_sphere$3) === false) return;

    //

    _inverseMatrix$2.copy(matrixWorld).invert();
    _ray$2.copy(raycaster.ray).applyMatrix4(_inverseMatrix$2);

    const localThreshold =
      threshold / ((this.scale.x + this.scale.y + this.scale.z) / 3);
    const localThresholdSq = localThreshold * localThreshold;

    if (geometry.isBufferGeometry) {
      const index = geometry.index;
      const attributes = geometry.attributes;
      const positionAttribute = attributes.position;

      if (index !== null) {
        const indices = index.array;

        for (let i = 0, il = indices.length; i < il; i++) {
          const a = indices[i];

          _position$1.fromBufferAttribute(positionAttribute, a);

          Points.testPoint(
            _position$1,
            a,
            localThresholdSq,
            matrixWorld,
            raycaster,
            intersects,
            this
          );
        }
      } else {
        for (let i = 0, l = positionAttribute.count; i < l; i++) {
          _position$1.fromBufferAttribute(positionAttribute, i);

          Points.testPoint(
            _position$1,
            i,
            localThresholdSq,
            matrixWorld,
            raycaster,
            intersects,
            this
          );
        }
      }
    } else {
      const vertices = geometry.vertices;

      for (let i = 0, l = vertices.length; i < l; i++) {
        Points.testPoint(
          vertices[i],
          i,
          localThresholdSq,
          matrixWorld,
          raycaster,
          intersects,
          this
        );
      }
    }
  }
}

class PointCloudTreeNode extends EventDispatcher {
  constructor() {
    super();
    this.needsTransformUpdate = true;
  }

  getChildren() {
    throw new Error("override function");
  }

  getBoundingBox() {
    throw new Error("override function");
  }

  isLoaded() {
    throw new Error("override function");
  }

  isGeometryNode() {
    throw new Error("override function");
  }

  isTreeNode() {
    throw new Error("override function");
  }

  getLevel() {
    throw new Error("override function");
  }

  getBoundingSphere() {
    throw new Error("override function");
  }
}

export class PointCloudOctreeGeometryNode extends PointCloudTreeNode {
  // Node in geometry.
  constructor(name, pcoGeometry, boundingBox) {
    super();

    this.id = PointCloudOctreeGeometryNode.IDCount++;
    this.name = name;
    this.index = parseInt(name.charAt(name.length - 1));
    this.pcoGeometry = pcoGeometry;
    this.geometry = null;
    this.boundingBox = boundingBox;
    this.boundingSphere = boundingBox.getBoundingSphere(new Sphere());
    this.children = {};
    this.numPoints = 0;
    this.level = null;
    this.loaded = false;
    this.oneTimeDisposeHandlers = [];
  }

  isGeometryNode() {
    return true;
  }

  getLevel() {
    return this.level;
  }

  isTreeNode() {
    return false;
  }

  isLoaded() {
    return this.loaded;
  }

  getBoundingSphere() {
    return this.boundingSphere;
  }

  getBoundingBox() {
    return this.boundingBox;
  }

  getChildren() {
    let children = [];

    for (let i = 0; i < 8; i++) {
      if (this.children[i]) {
        children.push(this.children[i]);
      }
    }

    return children;
  }

  getURL() {
    let url = "";

    url =
      this.pcoGeometry.octreeDir +
      "/" +
      this.getHierarchyPath() +
      "/" +
      this.name;

    return url;
  }

  getHierarchyPath() {
    let path = "r/";

    let hierarchyStepSize = this.pcoGeometry.hierarchyStepSize;
    let indices = this.name.substr(1);

    let numParts = Math.floor(indices.length / hierarchyStepSize);
    for (let i = 0; i < numParts; i++) {
      path += indices.substr(i * hierarchyStepSize, hierarchyStepSize) + "/";
    }

    path = path.slice(0, -1);

    return path;
  }

  addChild(child) {
    this.children[child.index] = child;
    child.parent = this;
  }

  load() {
    if (this.loading === true || this.loaded === true || !canLoadMore()) {
      return;
    }

    this.loading = true;

    startedLoading();

    if (
      this.level % this.pcoGeometry.hierarchyStepSize === 0 &&
      this.hasChildren
    ) {
      return this.loadHierachyThenPoints();
    } else {
      this.loadPoints();
    }
  }

  loadPoints() {
    this.pcoGeometry.loader.load(this);
  }

  loadHierachyThenPoints() {
    let node = this;

    // load hierarchy
    let callback = function (node, hbuffer) {
      const tStart = performance.now();

      let view = new DataView(hbuffer);

      let stack = [];
      let children = view.getUint8(0);
      let numPoints = view.getUint32(1, true);
      node.numPoints = numPoints;
      stack.push({
        children: children,
        numPoints: numPoints,
        name: node.name,
      });

      let decoded = [];

      let offset = 5;
      while (stack.length > 0) {
        let snode = stack.shift();
        let mask = 1;
        for (let i = 0; i < 8; i++) {
          if ((snode.children & mask) !== 0) {
            let childName = snode.name + i;

            let childChildren = view.getUint8(offset);
            let childNumPoints = view.getUint32(offset + 1, true);

            stack.push({
              children: childChildren,
              numPoints: childNumPoints,
              name: childName,
            });

            decoded.push({
              children: childChildren,
              numPoints: childNumPoints,
              name: childName,
            });

            offset += 5;
          }

          mask = mask * 2;
        }

        if (offset === hbuffer.byteLength) {
          break;
        }
      }

      let nodes = {};
      nodes[node.name] = node;
      const pco = node.pcoGeometry;

      for (let i = 0; i < decoded.length; i++) {
        let name = decoded[i].name;
        let decodedNumPoints = decoded[i].numPoints;
        let index = parseInt(name.charAt(name.length - 1));
        let parentName = name.substring(0, name.length - 1);
        let parentNode = nodes[parentName];
        let level = name.length - 1;
        let boundingBox = Utils.createChildAABB(parentNode.boundingBox, index);

        let currentNode = new PointCloudOctreeGeometryNode(
          name,
          pco,
          boundingBox
        );
        currentNode.level = level;
        currentNode.numPoints = decodedNumPoints;
        currentNode.hasChildren = decoded[i].children > 0;
        currentNode.spacing = pco.spacing / Math.pow(2, level);
        parentNode.addChild(currentNode);
        nodes[name] = currentNode;
      }

      const duration = performance.now() - tStart;
      if (duration > 5) {
        console.log(`duration: ${duration}ms, numNodes: ${decoded.length}`);
      }

      node.loadPoints();
    };

    const hurl =
      node.pcoGeometry.octreeDir +
      "/" +
      node.getHierarchyPath() +
      "/" +
      node.name +
      ".hrc";

    return new Promise((resolve) => {
      fetch(hurl, {
        method: "GET",
        credentials: "include",
      })
        .then((response) => response.arrayBuffer())
        .then((hbuffer) => {
          callback(node, hbuffer);
          resolve();
        })
        .catch((error) => {
          console.log(
            "Failed to load file! HTTP status: " + error + ", file: " + hurl
          );
          stoppedLoading();
        });
    });
  }

  getNumPoints() {
    return this.numPoints;
  }

  dispose() {
    if (this.geometry && this.parent != null) {
      this.geometry.dispose();
      this.geometry = null;
      this.loaded = false;

      this.dispatchEvent({ type: "dispose" });

      for (let i = 0; i < this.oneTimeDisposeHandlers.length; i++) {
        let handler = this.oneTimeDisposeHandlers[i];
        handler();
      }
      this.oneTimeDisposeHandlers = [];
    }
  }
}

PointCloudOctreeGeometryNode.IDCount = 0;

export class PointCloudOctreeNode extends PointCloudTreeNode {
  constructor() {
    super();

    this.children = [];
    this.sceneNode = null;
    this.octree = null;
  }

  getNumPoints() {
    return this.geometryNode.numPoints;
  }

  isLoaded() {
    return true;
  }

  isTreeNode() {
    return true;
  }

  isGeometryNode() {
    return false;
  }

  getLevel() {
    return this.geometryNode.level;
  }

  getBoundingSphere() {
    return this.geometryNode.boundingSphere;
  }

  getBoundingBox() {
    return this.geometryNode.boundingBox;
  }

  getChildren() {
    let children = [];

    for (let i = 0; i < 8; i++) {
      if (this.children[i]) {
        children.push(this.children[i]);
      }
    }

    return children;
  }

  getPointsInBox(boxNode) {
    if (!this.sceneNode) {
      return null;
    }

    let buffer = this.geometryNode.buffer;

    let posOffset = buffer.offset("position");
    let stride = buffer.stride;
    let view = new DataView(buffer.data);

    const worldToBox = boxNode.matrixWorld.clone().invert();
    const objectToBox = new Matrix4().multiplyMatrices(
      worldToBox,
      this.sceneNode.matrixWorld
    );

    let inBox = [];

    let pos = new Vector4();
    for (let i = 0; i < buffer.numElements; i++) {
      const x = view.getFloat32(i * stride + posOffset + 0, true);
      const y = view.getFloat32(i * stride + posOffset + 4, true);
      const z = view.getFloat32(i * stride + posOffset + 8, true);

      pos.set(x, y, z, 1);
      pos.applyMatrix4(objectToBox);

      if (-0.5 < pos.x && pos.x < 0.5) {
        if (-0.5 < pos.y && pos.y < 0.5) {
          if (-0.5 < pos.z && pos.z < 0.5) {
            pos.set(x, y, z, 1).applyMatrix4(this.sceneNode.matrixWorld);
            inBox.push(new Vector3(pos.x, pos.y, pos.z));
          }
        }
      }
    }

    return inBox;
  }

  get name() {
    return this.geometryNode.name;
  }
}

export const PointCloudRenderAttributes = {
  CLEARANCE: "clearance",
  HIGHLIGHT: "highlight",
  RAW_COLOR: "rgba",
  CLASSIFICATION: "classification",
};
export const PointCloudRenderAttributesIndexToValue = [
  PointCloudRenderAttributes.CLEARANCE,
  PointCloudRenderAttributes.HIGHLIGHT,
  PointCloudRenderAttributes.RAW_COLOR,
  PointCloudRenderAttributes.CLASSIFICATION,
];
export const PointCloudRenderAttributesValueToIndex = {
  CLEARANCE: 0,
  HIGHLIGHT: 1,
  RAW_COLOR: 2,
  CLASSIFICATION: 3,
};

let pcMaterial = null;
export function initPointCloudMaterial(activeAttributeName) {
  pcMaterial = new PointCloudMaterial();
  pcMaterial.size = 1;
  pcMaterial.pointSizeType = PointSizeType.ADAPTIVE;
  pcMaterial.shape = PointShape.SQUARE;
  pcMaterial.activeAttributeName = activeAttributeName;
}
export function getPointCloudMaterial() {
  return pcMaterial;
}

export class PointCloudOctree extends Object3D {
  _visible = true; // TODO: Clean this up. It can't be private because Object3D has a visible property. the only reason this exists is to make the "visibility_changed" event work.

  pointBudget = Infinity;
  visiblePointsTarget = 2_000_000;
  minimumNodePixelSize = 150;

  level = 0;

  showBoundingBox = false;

  constructor(geometry) {
    super();

    this.pcoGeometry = geometry;
    this.boundingBox = this.pcoGeometry.boundingBox;
    this.boundingSphere = this.boundingBox.getBoundingSphere(new Sphere());
    this.material = pcMaterial;
    this.position.copy(geometry.offset);
    this.updateMatrix();

    this.boundingBoxNodes = [];
    this.loadQueue = [];
    this.visibleBounds = new Box3();
    this.visibleNodes = [];
    this.visibleGeometry = [];
    this.name = "";

    {
      let box = [
        this.pcoGeometry.tightBoundingBox,
        this.getBoundingBoxWorld(),
      ].find((v) => v !== undefined);

      this.updateMatrixWorld(true);
      box = Utils.computeTransformedBoundingBox(box, this.matrixWorld);

      const bMin = box.min.z;
      const bMax = box.max.z;
      this.material.heightMin = bMin;
      this.material.heightMax = bMax;
    }

    this.projection = geometry.projection;
    this.fallbackProjection = geometry.fallbackProjection;

    this.root = this.pcoGeometry.root;
  }

  initialized() {
    return this.root !== null;
  }

  getAttribute(name) {
    const attribute = this.pcoGeometry.pointAttributes.attributes.find(
      (a) => a.name === name
    );

    if (attribute) {
      return attribute;
    } else {
      return null;
    }
  }

  getAttributes() {
    return this.pcoGeometry.pointAttributes;
  }

  toTreeNode(geometryNode, parent) {
    let node = new PointCloudOctreeNode();

    let sceneNode = new Points(geometryNode.geometry, this.material);
    sceneNode.name = geometryNode.name;
    sceneNode.position.copy(geometryNode.boundingBox.min);

    node.geometryNode = geometryNode;
    node.sceneNode = sceneNode;
    node.pointcloud = this;
    node.children = [];

    for (let i = 0; i < 8; i++) {
      node.children[i] = geometryNode.children[i];
    }

    if (!parent) {
      this.root = node;
      this.add(sceneNode);
    } else {
      const childIndex = parseInt(
        geometryNode.name[geometryNode.name.length - 1]
      );
      parent.sceneNode.add(sceneNode);
      parent.children[childIndex] = node;
    }

    let disposeListener = function () {
      let childIndex = parseInt(
        geometryNode.name[geometryNode.name.length - 1]
      );
      parent.sceneNode.remove(node.sceneNode);
      parent.children[childIndex] = geometryNode;
    };
    geometryNode.oneTimeDisposeHandlers.push(disposeListener);

    return node;
  }

  updateVisibleBounds() {
    let leafNodes = [];
    for (let i = 0; i < this.visibleNodes.length; i++) {
      let node = this.visibleNodes[i];
      let isLeaf = true;

      for (let j = 0; j < node.children.length; j++) {
        let child = node.children[j];
        if (child instanceof PointCloudOctreeNode) {
          isLeaf = isLeaf && !child.sceneNode.visible;
        } else if (child instanceof PointCloudOctreeGeometryNode) {
          isLeaf = true;
        }
      }

      if (isLeaf) {
        leafNodes.push(node);
      }
    }

    this.visibleBounds.min = new Vector3(Infinity, Infinity, Infinity);
    this.visibleBounds.max = new Vector3(-Infinity, -Infinity, -Infinity);
    for (let i = 0; i < leafNodes.length; i++) {
      let node = leafNodes[i];

      this.visibleBounds.expandByPoint(node.getBoundingBox().min);
      this.visibleBounds.expandByPoint(node.getBoundingBox().max);
    }
  }

  updateMaterial(material) {
    material.spacing = this.pcoGeometry.spacing;
    material.uniforms.octreeSize.value = this.pcoGeometry.boundingBox.getSize(
      new Vector3()
    ).x;
  }

  computeVisibilityTextureData(nodes, camera) {
    let data = new Uint8Array(nodes.length * 4);
    let visibleNodeTextureOffsets = new Map();

    // copy array
    nodes = nodes.slice();

    // sort by level and index, e.g. r, r0, r3, r4, r01, r07, r30, ...
    let sort = function (a, b) {
      let na = a.geometryNode.name;
      let nb = b.geometryNode.name;
      if (na.length !== nb.length) return na.length - nb.length;
      if (na < nb) return -1;
      if (na > nb) return 1;
      return 0;
    };
    nodes.sort(sort);

    let nodeMap = new Map();
    let offsetsToChild = new Array(nodes.length).fill(Infinity);

    for (let i = 0; i < nodes.length; i++) {
      let node = nodes[i];

      nodeMap.set(node.name, node);
      visibleNodeTextureOffsets.set(node, i);

      if (i > 0) {
        let index = parseInt(node.name.slice(-1));
        let parentName = node.name.slice(0, -1);
        let parent = nodeMap.get(parentName);
        let parentOffset = visibleNodeTextureOffsets.get(parent);

        let parentOffsetToChild = i - parentOffset;

        offsetsToChild[parentOffset] = Math.min(
          offsetsToChild[parentOffset],
          parentOffsetToChild
        );

        data[parentOffset * 4 + 0] = data[parentOffset * 4 + 0] | (1 << index);
        data[parentOffset * 4 + 1] = offsetsToChild[parentOffset] >> 8;
        data[parentOffset * 4 + 2] = offsetsToChild[parentOffset] % 256;
      }

      let density = node.geometryNode.density;

      if (typeof density === "number") {
        const lodOffset = Math.log2(density) / 2 - 1.5;
        const offsetUint8 = (lodOffset + 10) * 10;

        data[i * 4 + 3] = offsetUint8;
      } else {
        data[i * 4 + 3] = 100;
      }
    }

    return {
      data: data,
      offsets: visibleNodeTextureOffsets,
    };
  }

  deepestNodeAt(position) {
    const toObjectSpace = this.matrixWorld.clone().invert();
    const objPos = position.clone().applyMatrix4(toObjectSpace);

    let current = this.root;
    while (true) {
      let containingChild = null;

      for (const child of current.children) {
        if (child !== undefined) {
          if (child.getBoundingBox().containsPoint(objPos)) {
            containingChild = child;
          }
        }
      }

      if (containingChild instanceof PointCloudOctreeNode) {
        current = containingChild;
      } else {
        break;
      }
    }

    const deepest = current;
    return deepest;
  }

  nodesOnRay(nodes, ray) {
    let nodesOnRay = [];

    for (let i = 0; i < nodes.length; i++) {
      let node = nodes[i];
      let sphere = node
        .getBoundingSphere()
        .clone()
        .applyMatrix4(this.matrixWorld);

      if (ray.intersectsSphere(sphere)) {
        nodesOnRay.push(node);
      }
    }

    return nodesOnRay;
  }

  updateMatrixWorld(force) {
    if (this.matrixAutoUpdate === true) this.updateMatrix();

    if (this.matrixWorldNeedsUpdate === true || force === true) {
      if (!this.parent) {
        this.matrixWorld.copy(this.matrix);
      } else {
        this.matrixWorld.multiplyMatrices(this.parent.matrixWorld, this.matrix);
      }

      this.matrixWorldNeedsUpdate = false;

      force = true;
    }
  }

  hideDescendants(object) {
    let stack = [];
    for (let i = 0; i < object.children.length; i++) {
      let child = object.children[i];
      if (child.visible) {
        stack.push(child);
      }
    }

    while (stack.length > 0) {
      let object = stack.shift();

      object.visible = false;

      for (let i = 0; i < object.children.length; i++) {
        let child = object.children[i];
        if (child.visible) {
          stack.push(child);
        }
      }
    }
  }

  getBoundingBoxWorld() {
    this.updateMatrixWorld(true);
    let box = this.boundingBox;
    let transform = this.matrixWorld;
    let tBox = Utils.computeTransformedBoundingBox(box, transform);

    return tBox;
  }

  getVisibleExtent() {
    return this.visibleBounds.applyMatrix4(this.matrixWorld);
  }

  /**
   * params.pickWindowSize:  Look for points inside a pixel window of this size.
   *  Use odd values: 1, 3, 5, ...
   */
  pick(viewer, camera, ray, params = {}) {
    const renderer = viewer.webGlRenderer;
    const pRenderer = viewer.pRenderer;

    const pickWindowSize = params.pickWindowSize ?? 10;
    const pointSizeType = params.pointSizeType ?? this.material.pointSizeType;
    const pointSize = params.pointSize ?? this.material.size;

    const size = viewer.renderContext.size;

    const width = Math.ceil(params.width ?? size.width);
    const height = Math.ceil(params.height ?? size.height);

    const pixelPosX = params.x;
    const pixelPosY = params.y;

    const nodes = this.nodesOnRay(this.visibleNodes, ray);

    if (nodes.length === 0) {
      return null;
    }

    // Initialize pickState for the Octree.
    if (!this.pickState) {
      const material = new PointCloudMaterial();

      const renderTarget = new WebGLRenderTarget(1, 1, {
        minFilter: LinearFilter,
        magFilter: NearestFilter,
        format: RGBAFormat,
      });

      this.pickState = {
        renderTarget: renderTarget,
        material: material,
      };
    }

    let pickState = this.pickState;
    let pickMaterial = pickState.material;

    // Update pick material
    pickMaterial.pointSizeType = pointSizeType;
    pickMaterial.shape = PointShape.PARABOLOID;

    pickMaterial.uniforms.uFilterReturnNumberRange.value =
      this.material.uniforms.uFilterReturnNumberRange.value;
    pickMaterial.uniforms.uFilterNumberOfReturnsRange.value =
      this.material.uniforms.uFilterNumberOfReturnsRange.value;
    pickMaterial.uniforms.uFilterGPSTimeClipRange.value =
      this.material.uniforms.uFilterGPSTimeClipRange.value;
    pickMaterial.uniforms.uFilterPointSourceIDClipRange.value =
      this.material.uniforms.uFilterPointSourceIDClipRange.value;

    pickMaterial.activeAttributeName = "indices";

    pickMaterial.size = pointSize;
    pickMaterial.uniforms.minSize.value = this.material.uniforms.minSize.value;
    pickMaterial.uniforms.maxSize.value = this.material.uniforms.maxSize.value;
    pickMaterial.classification = this.material.classification;
    pickMaterial.clearance = this.material.clearance;
    pickMaterial.warningData = this.material.warningData;
    pickMaterial.recomputeClassification();

    if (params.pickClipped) {
      pickMaterial.clipBoxes = this.material.clipBoxes;
      pickMaterial.clipBoxTypes = this.material.clipBoxTypes;
      pickMaterial.uniforms.clipBoxes = this.material.uniforms.clipBoxes;
      pickMaterial.uniforms.clipBoxTypes = this.material.uniforms.clipBoxTypes;
      if (this.material.clipTask === ClipTask.HIGHLIGHT) {
        pickMaterial.clipTask = ClipTask.NONE;
      } else {
        pickMaterial.clipTask = this.material.clipTask;
      }
      pickMaterial.clipMethod = this.material.clipMethod;
    } else {
      pickMaterial.clipBoxes = [];
      pickMaterial.clipBoxTypes = [];
    }

    this.updateMaterial(pickMaterial);

    pickState.renderTarget.setSize(width, height);

    let clamp = (number, min, max) => Math.min(Math.max(min, number), max);

    const x = parseInt(clamp(pixelPosX - (pickWindowSize - 1) / 2, 0, width));
    const y = parseInt(clamp(pixelPosY - (pickWindowSize - 1) / 2, 0, height));
    const w = parseInt(Math.min(x + pickWindowSize, width) - x);
    const h = parseInt(Math.min(y + pickWindowSize, height) - y);

    const gl = viewer.gl;
    gl.enable(gl.SCISSOR_TEST);
    gl.scissor(x, y, w, h);

    renderer.state.buffers.depth.setTest(pickMaterial.depthTest);
    renderer.state.buffers.depth.setMask(pickMaterial.depthWrite);
    renderer.state.setBlending(NoBlending);

    // RENDER
    renderer.setRenderTarget(pickState.renderTarget);
    gl.clearColor(0, 0, 0, 0);
    renderer.clear(true, true, true);

    // Make sure the shader has been created before rendering.
    pRenderer.prepareOctrees();

    pRenderer.renderOctree(this, camera, pickState.renderTarget, {
      material: pickMaterial,
      nodes: nodes,
    });

    const pixelCount = w * h;
    let buffer = new Uint8Array(4 * pixelCount);

    gl.readPixels(x, y, w, h, gl.RGBA, gl.UNSIGNED_BYTE, buffer);

    renderer.setRenderTarget(null);
    renderer.state.reset();
    renderer.setScissorTest(false);
    gl.disable(gl.SCISSOR_TEST);

    const pixels = buffer;
    const ibuffer = new Uint32Array(buffer.buffer);

    // find closest hit inside pixelWindow boundaries
    let hits = [];
    for (let u = 0; u < pickWindowSize; u++) {
      for (let v = 0; v < pickWindowSize; v++) {
        const offset = u + v * pickWindowSize;
        const distance =
          Math.pow(u - (pickWindowSize - 1) / 2, 2) +
          Math.pow(v - (pickWindowSize - 1) / 2, 2);

        const pointCloudIndex = pixels[4 * offset + 3];
        pixels[4 * offset + 3] = 0;
        const pointIndex = ibuffer[offset];

        if (
          !(pointCloudIndex === 0 && pointIndex === 0) &&
          pointCloudIndex !== undefined &&
          pointIndex !== undefined
        ) {
          const hit = {
            pIndex: pointIndex, // Point
            pcIndex: pointCloudIndex, // Point cloud
            distanceToCenter: distance,
          };

          if (params.all) {
            hits.push(hit);
          } else {
            if (hits.length > 0) {
              if (distance < hits[0].distanceToCenter) {
                hits[0] = hit;
              }
            } else {
              hits.push(hit);
            }
          }
        }
      }
    }

    for (let hit of hits) {
      let point = {};

      if (!nodes[hit.pcIndex]) {
        return null;
      }

      const node = nodes[hit.pcIndex];
      const pc = node.sceneNode;
      const geometry = node.geometryNode.geometry;

      for (let attributeName in geometry.attributes) {
        const attribute = geometry.attributes[attributeName];

        if (attributeName === "position") {
          const x = attribute.array[3 * hit.pIndex + 0];
          const y = attribute.array[3 * hit.pIndex + 1];
          const z = attribute.array[3 * hit.pIndex + 2];

          const position = new Vector3(x, y, z);
          position.applyMatrix4(pc.matrixWorld);

          point[attributeName] = position;
        } else if (attributeName !== "indices") {
          let values = attribute.array.slice(
            attribute.itemSize * hit.pIndex,
            attribute.itemSize * (hit.pIndex + 1)
          );

          if (attribute.potree) {
            const { scale, offset } = attribute.potree;
            values = values.map((v) => v / scale + offset);
          }

          point[attributeName] = values;
        }
      }

      hit.point = point;
    }

    if (params.all) {
      return hits.map((hit) => hit.point);
    } else {
      if (hits.length === 0) {
        return null;
      } else {
        return hits[0].point;
      }
    }
  }

  get progress() {
    return this.visibleNodes.length / this.visibleGeometry.length;
  }

  find(name) {
    let node = null;
    for (let char of name) {
      if (char === "r") {
        node = this.root;
      } else {
        node = node.children[char];
      }
    }

    return node;
  }

  get visible() {
    return this._visible;
  }

  set visible(value) {
    if (value !== this._visible) {
      this._visible = value;
    }
  }
}

export function updatePointClouds(pointclouds, camera, renderContext) {
  const result = updateVisibility(pointclouds, camera, renderContext);

  pcMaterial.fov = camera.fov * (Math.PI / 180);
  pcMaterial.screenWidth = renderContext.canvas.clientWidth;
  pcMaterial.screenHeight = renderContext.canvas.clientHeight;
  pcMaterial.near = camera.near;
  pcMaterial.far = camera.far;

  for (const pointcloud of pointclouds) {
    pointcloud.updateMaterial(pointcloud.material);
    pointcloud.updateVisibleBounds();
  }

  lru.freeMemory();

  return result;
}

let _pointcloudTransformVersion = new Map();
function updateVisibility(pointclouds, camera, renderContext) {
  let numVisiblePoints = 0;

  let numVisiblePointsInPointclouds = new Map(pointclouds.map((pc) => [pc, 0]));

  let visibleNodes = [];
  let visibleGeometry = [];
  let unloadedGeometry = [];

  let lowestSpacing = Infinity;

  // calculate object space frustum and cam pos and setup priority queue
  const visStructures = updateVisibilityStructures(pointclouds, camera);
  const frustums = visStructures.frustums;
  const camObjPositions = visStructures.camObjPositions;
  let priorityQueue = visStructures.priorityQueue;

  let loadedToGPUThisFrame = 0;

  const domHeight = renderContext.canvas.clientHeight;

  // check if pointcloud has been transformed
  // some code will only be executed if changes have been detected

  let pointcloudTransformVersion = _pointcloudTransformVersion;
  for (let pointcloud of pointclouds) {
    if (!pointcloud.visible) {
      continue;
    }

    pointcloud.updateMatrixWorld();

    if (!pointcloudTransformVersion.has(pointcloud)) {
      pointcloudTransformVersion.set(pointcloud, {
        number: 0,
        transform: pointcloud.matrixWorld.clone(),
      });
    } else {
      let version = pointcloudTransformVersion.get(pointcloud);

      if (!version.transform.equals(pointcloud.matrixWorld)) {
        version.number++;
        version.transform.copy(pointcloud.matrixWorld);
      }
    }
  }

  while (priorityQueue.size() > 0) {
    const element = priorityQueue.pop();
    const parent = element.parent;
    const pointcloud = pointclouds[element.pointcloud];
    let node = element.node;

    const box = node.getBoundingBox();
    const frustum = frustums[element.pointcloud];
    const camObjPos = camObjPositions[element.pointcloud];

    const insideFrustum = frustum.intersectsBox(box);
    const maxLevel = pointcloud.maxLevel || Infinity;
    const level = node.getLevel();

    let visible = insideFrustum;
    visible =
      visible && !(numVisiblePoints + node.getNumPoints() > getPointBudget());
    visible =
      visible &&
      !(
        numVisiblePointsInPointclouds.get(pointcloud) + node.getNumPoints() >
        pointcloud.pointBudget
      );
    visible = visible && level < maxLevel;
    visible = visible || node.getLevel() <= 2;

    const clipBoxes = pointcloud.material.clipBoxes;
    if (
      visible &&
      clipBoxes.length > 0 &&
      pointcloud.material.clipTask === ClipTask.SHOW_INSIDE
    ) {
      const testIntersection = (clipBox) => {
        let pcWorldInverse = pointcloud.matrixWorld.clone().invert();
        pcWorldInverse.multiply(clipBox.box.matrixWorld);

        const px = new Vector3(+0.5, 0, 0).applyMatrix4(pcWorldInverse);
        const nx = new Vector3(-0.5, 0, 0).applyMatrix4(pcWorldInverse);
        const py = new Vector3(0, +0.5, 0).applyMatrix4(pcWorldInverse);
        const ny = new Vector3(0, -0.5, 0).applyMatrix4(pcWorldInverse);
        const pz = new Vector3(0, 0, +0.5).applyMatrix4(pcWorldInverse);
        const nz = new Vector3(0, 0, -0.5).applyMatrix4(pcWorldInverse);

        const pxN = new Vector3().subVectors(nx, px).normalize();
        const nxN = pxN.clone().multiplyScalar(-1);
        const pyN = new Vector3().subVectors(ny, py).normalize();
        const nyN = pyN.clone().multiplyScalar(-1);
        const pzN = new Vector3().subVectors(nz, pz).normalize();
        const nzN = pzN.clone().multiplyScalar(-1);

        const pxPlane = new Plane().setFromNormalAndCoplanarPoint(pxN, px);
        const nxPlane = new Plane().setFromNormalAndCoplanarPoint(nxN, nx);
        const pyPlane = new Plane().setFromNormalAndCoplanarPoint(pyN, py);
        const nyPlane = new Plane().setFromNormalAndCoplanarPoint(nyN, ny);
        const pzPlane = new Plane().setFromNormalAndCoplanarPoint(pzN, pz);
        const nzPlane = new Plane().setFromNormalAndCoplanarPoint(nzN, nz);

        const frustum = new Frustum(
          pxPlane,
          nxPlane,
          pyPlane,
          nyPlane,
          pzPlane,
          nzPlane
        );

        return frustum.intersectsBox(box);
      };

      const clipMethod = pointcloud.material.clipMethod;
      if (clipMethod === ClipMethod.INSIDE_ANY) {
        let intersectsAny = false;
        for (const clipBox of clipBoxes) {
          if (testIntersection(clipBox)) {
            intersectsAny = true;
            break;
          }
        }

        visible = intersectsAny;
      } else if (clipMethod === ClipMethod.INSIDE_ALL) {
        for (const clipBox of clipBoxes) {
          if (!testIntersection(clipBox)) {
            visible = false;
            break;
          }
        }
      }
    }

    if (node.spacing) {
      lowestSpacing = Math.min(lowestSpacing, node.spacing);
    } else if (node.geometryNode && node.geometryNode.spacing) {
      lowestSpacing = Math.min(lowestSpacing, node.geometryNode.spacing);
    }

    if (numVisiblePoints + node.getNumPoints() > getPointBudget()) {
      break;
    }

    if (!visible) {
      continue;
    }

    numVisiblePoints += node.getNumPoints();
    const numVisiblePointsInPointcloud =
      numVisiblePointsInPointclouds.get(pointcloud);
    numVisiblePointsInPointclouds.set(
      pointcloud,
      numVisiblePointsInPointcloud + node.getNumPoints()
    );
    pointcloud.numVisibleNodes++;
    pointcloud.numVisiblePoints += node.getNumPoints();

    if (node.isGeometryNode() && (!parent || parent.isTreeNode())) {
      if (node.isLoaded() && loadedToGPUThisFrame < 2) {
        node = pointcloud.toTreeNode(node, parent);
        loadedToGPUThisFrame++;
      } else {
        unloadedGeometry.push(node);
        visibleGeometry.push(node);
      }
    }

    if (node.isTreeNode()) {
      lru.touch(node.geometryNode);
      node.sceneNode.visible = true;
      node.sceneNode.material = pointcloud.material;

      visibleNodes.push(node);
      pointcloud.visibleNodes.push(node);

      if (node._transformVersion === undefined) {
        node._transformVersion = -1;
      }
      const transformVersion = pointcloudTransformVersion.get(pointcloud);
      if (node._transformVersion !== transformVersion.number) {
        node.sceneNode.updateMatrix();
        node.sceneNode.matrixWorld.multiplyMatrices(
          pointcloud.matrixWorld,
          node.sceneNode.matrix
        );
        node._transformVersion = transformVersion.number;
      }

      if (
        pointcloud.showBoundingBox &&
        !node.boundingBoxNode &&
        node.getBoundingBox
      ) {
        let boxHelper = new Box3Helper$1(node.getBoundingBox());
        boxHelper.matrixAutoUpdate = false;
        pointcloud.boundingBoxNodes.push(boxHelper);
        node.boundingBoxNode = boxHelper;
        node.boundingBoxNode.matrix.copy(pointcloud.matrixWorld);
      } else if (pointcloud.showBoundingBox) {
        node.boundingBoxNode.visible = true;
        node.boundingBoxNode.matrix.copy(pointcloud.matrixWorld);
      } else if (node.boundingBoxNode) {
        node.boundingBoxNode.visible = false;
      }
    }

    // Add child nodes to priorityQueue
    const children = node.getChildren();
    for (let i = 0; i < children.length; i++) {
      const child = children[i];

      let weight = 0;
      if (camera.isPerspectiveCamera) {
        const sphere = child.getBoundingSphere(); //BOOKMARK

        const distance = camObjPos.distanceTo(sphere.center);

        const radius = sphere.radius;

        const fov = (camera.fov * Math.PI) / 180;
        const slope = Math.tan(fov / 2);
        const projFactor = (0.5 * domHeight) / (slope * distance);
        const screenPixelRadius = radius * projFactor;

        if (screenPixelRadius < pointcloud.minimumNodePixelSize) {
          continue;
        }

        weight = screenPixelRadius;

        if (distance - radius < 0) {
          weight = Number.MAX_VALUE;
        }
      } else {
        const bb = child.getBoundingBox();
        const diagonal = bb.max.clone().sub(bb.min).length();

        weight = diagonal;
      }

      priorityQueue.push({
        pointcloud: element.pointcloud,
        node: child,
        parent: node,
        weight: weight,
      });
    }
  } // end priority queue loop

  for (let i = 0; i < Math.min(maxNodesLoading, unloadedGeometry.length); i++) {
    unloadedGeometry[i].load();
  }

  return {
    visibleNodes: visibleNodes,
    numVisiblePoints: numVisiblePoints,
    lowestSpacing: lowestSpacing,
  };
}

function updateVisibilityStructures(pointclouds, camera) {
  let frustums = [];
  let camObjPositions = [];
  let priorityQueue = new BinaryHeap(function (x) {
    return 1 / x.weight;
  });

  for (let i = 0; i < pointclouds.length; i++) {
    let pointcloud = pointclouds[i];

    if (!pointcloud.initialized()) {
      continue;
    }

    pointcloud.numVisibleNodes = 0;
    pointcloud.numVisiblePoints = 0;
    pointcloud.deepestVisibleLevel = 0;
    pointcloud.visibleNodes = [];
    pointcloud.visibleGeometry = [];

    // Update Camera Matrix.
    camera.updateMatrixWorld();
    const view = camera.matrixWorld;
    const viewI = camera.matrixWorldInverse;
    const world = pointcloud.matrixWorld;
    const worldI = world.clone().invert();
    const proj = camera.projectionMatrix;

    // Calculate frustum in object space.
    const fm = new Matrix4().multiply(proj).multiply(viewI).multiply(world);
    let frustum = new Frustum();
    frustum.setFromProjectionMatrix(fm);
    frustums.push(frustum);

    // Calculate camera position in object space.
    const camMatrixObject = new Matrix4().multiply(worldI).multiply(view);
    const camObjPos = new Vector3().setFromMatrixPosition(camMatrixObject);
    camObjPositions.push(camObjPos);

    if (pointcloud.visible && pointcloud.root !== null) {
      priorityQueue.push({
        pointcloud: i,
        node: pointcloud.root,
        weight: Number.MAX_VALUE,
      });
    }

    if (pointcloud.root.isTreeNode()) {
      pointcloud.hideDescendants(pointcloud.root.sceneNode);
    }

    for (let j = 0; j < pointcloud.boundingBoxNodes.length; j++) {
      pointcloud.boundingBoxNodes[j].visible = false;
    }
  }

  return {
    frustums: frustums,
    camObjPositions: camObjPositions,
    priorityQueue: priorityQueue,
  };
}
